const BASE_URL = "/api/products";

// Get all products
export async function getProduct() {
  return await fetch(`${BASE_URL}`, getProductsGet()).then((res) => res.json());
}

//Add a product
export async function postProduct(props) {
  return await fetch(`${BASE_URL}/create`, getProductsPost(props)).then((res) =>
    res.json()
  );
}

//Edit a product
export async function editProduct(id, data) {
  return await fetch(`${BASE_URL}/${id}`, getProductsPost(data)).then((res) =>
    res.json()
  );
}

//Delete a product
export async function deleteProduct(id) {
  return await fetch(`${BASE_URL}/${id}`, getProductsDelete()).then((res) =>
    res.json()
  );
}
/*-- Helper Functions --*/

function getProductsGet() {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    mode: "no-cors",
  };
}

function getProductsPost({ name, img, desc }) {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ name, img, desc }),
  };
}

function getProductsDelete() {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
    },
  };
}

//   function getProductsPut() {
//     return {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getToken()}`
//       }
//     };
//   }
