const BASE_URL = "/api/conditionsTreated";

// Get all conditions
export async function getConditions(tag) {
  return await fetch(`${BASE_URL}`, getConditionsPost(tag)).then((res) =>
    res.json()
  );
}

export async function postCondition(props) {
  return await fetch(`${BASE_URL}/create`, getConditionsPost(props)).then(
    (res) => res.json()
  );
}

//Edit a product
export async function editCondition(id, data) {
  return await fetch(`${BASE_URL}/${id}`, getConditionsPost(data)).then((res) =>
    res.json()
  );
}

export async function deleteCondition(id) {
  return await fetch(`${BASE_URL}/${id}`, getConditionDelete()).then((res) =>
    res.json()
  );
}

/*-- Helper Functions --*/

// function getConditionsGet() {
//   return {
//     headers: {
//       "Content-Type": "application/json",
//       // Accept: "application/json",
//     },
//     mode: "no-cors",
//   };
// }

function getConditionsPost(data) {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}` //Customer has search function which use POST
    },
    body: JSON.stringify(data),
  };
}

function getConditionDelete() {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}` //Customer has search function which use POST
    },
  };
}

//   function getConditionsPut() {
//     return {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getToken()}`
//       }
//     };
//   }
