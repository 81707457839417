import React from "react";
import "./footer.css";

import BusinessInfo from "../footer-items/business-info";
import HoursInfo from "../footer-items/hours-info";
import SiteNavigation from "../footer-items/site-navigation";
import SocialsInfo from "../footer-items/socials-info";

export default function Footer({setUser}) {
  return (
    <>
      <div className="footer-container">
        <BusinessInfo setUser={setUser}/>
        <HoursInfo />
        <SiteNavigation />
        <SocialsInfo />
      </div>
    </>
  );
}
