import React from "react";
import "./footer-items.css";
import scrollImg from "./scrollImg.png";

import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

export default function SocialsInfo() {
  return (
    <>
      <div className="socials-container">
        <div className="socials-logo-container">
          <img className="socials-logo" src={scrollImg} alt="Leaf.png" />
        </div>
        <div className="socials-header-icons-container">
          <span className="socials-header">Follow Us</span>
          <div className="socials-icons-block">
            <div className="socials-icons">
              <a
                href="https://www.instagram.com/proevowellness/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://www.facebook.com/proevolutionwellnessinc/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
