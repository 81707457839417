const BASE_URL = '/api/staff';

//Get all staff information
export async function getAllStaff(){
    console.log("before running fetch in getAllStaff")
    return await fetch(`${BASE_URL}/allstaff`, getOptionsGet()).then(res => res.json());
    
}

//Create a staff
export async function postStaff(props) {
    console.log("before running fetch in postStaff")
    return await fetch(`${BASE_URL}/create`, getOptionsPost(props)).then((res) => res.json());
}

//Edit a staff
export async function editStaff(id, data) {
    return await fetch(`${BASE_URL}/update/${id}`, getOptionsPut(data)).then((res) => res.json());
}

//Delete a staff
export async function deleteStaff(id) {
    return await fetch(`${BASE_URL}/delete/${id}`, getOptionsDelete()).then((res) => res.json());
}

/*-- Helper Functions --*/

function getOptionsGet() {
    return {
        // method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        mode: 'no-cors'
    }
}

function getOptionsPost(data) {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${getToken()}`
      }, 
      body: JSON.stringify(data),
    };
}

function getOptionsPut(data) {
    return {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${getToken()}`
      }, 
      body: JSON.stringify(data),
    };
}

function getOptionsDelete() {
    return {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${getToken()}` //Customer has search function which use POST
      },
    };
}

