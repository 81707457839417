import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import "./AdminFaqComp.css";

const AdminFaqComp = ({
  user,
  quest,
  ans,
  faqId,
  modalOpen,
  setModalOpen,
  setDeleteModalOpen,
  deleteModalOpen,
  setFaqId,
  handleDelete,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="admin-faq-comp-container">
      <div className="admin-faq-quest-comp">
        <div id="greenbox" onClick={() => setOpen(!open)}>
          <div id={open ? "plus-sign-vert-open" : "plus-sign-vert"}></div>
          <div id="plus-sign-hort"></div>
        </div>
        <div className="admin-faq-title-edit-delete-container">
          <div id="admin-faq-quest-title">{quest ? quest : "loading...."}</div>
          <div className="admin-faq-buttons-container">
            <button
              className={
                "admin-faq-component-btn admin-faq-edit-btn " +
                (!user.user.isAdmin ? "disabled-button" : "")
              }
              onClick={() => {
                setModalOpen(!modalOpen);
                setFaqId(faqId);
              }}
              disabled={!user.user.isAdmin ? "true" : ""}
            >
              <FiEdit2 size={"15px"} />
            </button>
            {/* <button
              className={
                "admin-faq-component-btn admin-faq-del-btn " +
                (!user.user.isAdmin ? "disabled-button" : "")
              }
              onClick={() => handleDelete(faqId)}
              disabled={!user.user.isAdmin ? "true" : ""}
            >
              <RiDeleteBin6Line size={"15px"} />
            </button> */}
            <button
              className={
                "admin-faq-component-btn admin-faq-del-btn " +
                (!user.user.isAdmin ? "disabled-button" : "")
              }
              onClick={() => {
                setDeleteModalOpen(!deleteModalOpen);
                setFaqId(faqId);
              }}
              disabled={!user.user.isAdmin ? "true" : ""}
            >
              <RiDeleteBin6Line size={"15px"} />
            </button>
          </div>
        </div>
      </div>
      {open && <div id="admin-faq-ans">{ans}</div>}

      <div id="hard-line" />
    </div>
  );
};

export default AdminFaqComp;
