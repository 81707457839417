import React,{useState} from 'react'
import './AuthForm.css'
import profilePic from '../../images/auth/avatar icon.webp'
import { login } from '../../utils/usersServices';

const AuthForm = ({authOpen,authChange,setUser}) => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');

  function handleChange(e) {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError('');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const user = await login(credentials);
      authChange(false)
      setUser(user)

    } catch {
      setError('Log In Failed - Try Again');
      console.log(error)
    }
  }

  return (
    <div className='auth-container'>
      <div className="close-form-container">
        <div className='close-form' onClick={()=>authChange(!authOpen)}>X</div>
      </div>
      <div className="auth-content-container">
        <span id='login-title'>LOGIN</span>
        <img className="auth-profile-pic" src={profilePic} alt='proevoltion-profile-pic'></img>
        <form id='form-container' onSubmit={handleSubmit}>
          <div className='input-container'>
            <input className='auth-input' placeholder='Email' name='email' value={credentials.email} onChange={handleChange} required/>
            <input className='auth-input' placeholder='Password' name ='password' type='password' value={credentials.password} onChange={handleChange} required/>
          </div>
          <div className='button-container'>
            <button type='submit' className='auth-button'>Login</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AuthForm