import React, { useState } from "react";
import "./RatesModalForm.css";

import { postServiceRate } from "../../utils/ratesApi";
import { editServiceRate } from "../../utils/ratesApi";

export default function RateModalForm({
  modalChange,
  modalOpen,
  serviceId,
  serviceRateId,
  setCurrentRateId,
  services,
}) {
  
  let adminRateEdit = { rateId: null, name: "", price: 0, is_hst: false, }

  if (serviceRateId) {
    let adminServiceFound = services.find(
      (service) => service._id === serviceId
    );
    let rateFound = adminServiceFound.rates.find(
      (rate) => rate._id === serviceRateId
    );
    adminRateEdit = {
      rateId: serviceRateId,
      name: rateFound.name,
      price: rateFound.price,
      is_hst: rateFound.is_hst,
    };
  }

  const [formData, setFormData] = useState(adminRateEdit);
  const { name, price, is_hst } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleCheckboxChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.checked,
    };
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (serviceRateId === null) {
      await postServiceRate(serviceId, formData);
      console.log("data sent to utils");
      alert("Service Rate Added");
    } else {
      await editServiceRate(serviceId, formData);
      alert("Service Rate Edited");
    }

    // setFormData = {
    //   name: "",
    //   price: null,
    //   is_hst: false,
    // };
    setCurrentRateId(null);
    setFormData({ name: "", price: 0, is_hst: false });
    modalChange(!modalOpen);
  };

  return (
    <>
      <div className="modal-admin-rates-container">
        <div className="modal-admin-rates-content-container">
          <div className='modal-admin-rates-close-form' onClick={()=>modalChange(!modalOpen)}>
            <div className="modal-admin-rates-x">X</div>
          </div>
          <div className="admin-ratesHeader">
            {serviceRateId === null ? (
              <span className="modal-admin-rates-title">Add Service Rate</span>
            ) : (
              <span className="modal-admin-rates-title">Edit Service Rate</span>
            )}
          </div>
          <form action="" onSubmit={handleSubmit} className="condition__form">
              <div className="modal-admin-rates-input-container">
                <div>
                  <input
                    className="modal-admin-rates-input-field"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Rate Title"
                    autoComplete="off"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div style={{ width: "100%", backgroundColor: "Red" }}>
                  <input
                    className="modal-admin-rates-input-field"
                    type="number"
                    name="price"
                    id="price"
                    min="0"
                    step="0.01"
                    placeholder={0}
                    autoComplete="off"
                    value={price}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="modal-admin-rates-label" style={{ marginRight: "7px" }}>+ HST?</label>
                  <input
                    className="modal-admin-rates-checkbox-field"
                    type="checkbox"
                    name="is_hst"
                    id="is_hst"
                    value={is_hst}
                    onChange={handleCheckboxChange}
                    checked={is_hst}
                  />
                </div>
              </div>
            <div style={{ marginTop: "9px" }}>
              <button className="modal-admin-rates-button" type="submit" style={{ marginRight: "5px" }}>
                {!serviceRateId ? "Add Service Rate" : "Edit Service Rate"}
              </button>
              <button
                className="modal-admin-rates-button" 
                onClick={() => {
                  modalChange(!modalOpen);
                  setCurrentRateId(null);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
