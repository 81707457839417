import React, { useState } from "react";
import { postFaq } from "../../utils/faqApi";
import { editFaq } from "../../utils/faqApi";

import "./FaqModalForm.css";

const FaqModalForm = ({
  modalChange,
  modalOpen,
  faqId, 
  setFaqId, 
  faqs, 
}) => {

  let faqEdit = { question: "", answer: ""}

  if (faqId) {
    let faqFound = faqs.find((faq) => faq._id === faqId);
    faqEdit = {
      question: faqFound.question,
      answer: faqFound.answer, 
    }
  }

  const [formData, setFormData] = useState(faqEdit);
  const { question, answer } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
        question, 
        answer, 
    };
    if (faqId === null) {
      await postFaq(data);
      alert("Faq Create Successful!");
    } else {
      await editFaq(faqId, data);
      alert("Faq Update Successful!");
    }
    setFormData({ question: "", answer: "" });
    modalChange(!modalOpen);
  };

  return (
    <div className="admin__faq">
      <div className="modal-admin-faq-container">
        <div className='modal-admin-faq-close-form' onClick={()=>modalChange(!modalOpen)}>
          <div className="modal-admin-faq-x">X</div>
        </div>

        <div className="modal-admin-faq-title-container">
          {faqId === null ? (
            <span className="modal-admin-faq-title">Add FAQ</span>
          ) : (
            <span className="modal-admin-faq-title">Edit FAQ</span>
          )}
        </div>
        <form action="" className="faq__form" onSubmit={handleSubmit}>
          <div className="modal-admin-faq-input-container">
            <div>
              <textarea
                className="modal-admin-faq-input-field"
                type="text"
                name="question"
                id="desc"
                placeholder="Question"
                value={question}
                onChange={handleChange}
                style={{ marginBottom: "10px", textAlign: "left", fontSize: "16px", fontWeight: 700}}
              />
            </div>
            <div>
              <textarea
                className="modal-admin-faq-input-field"
                type="text"
                name="answer"
                id="desc"
                placeholder="Answer"
                value={answer}
                onChange={handleChange}
                style={{ marginBottom: "10px", textAlign: "left", fontSize: "16px", fontWeight: 700}}
              />
            </div>
            <div className="modal-admin-faq-buttons-container">
              <button className="modal-admin-faq-button" type="submit">
                {!faqId ? "Add FAQ" : "Edit FAQ"}
              </button>
              <button
                className="modal-admin-faq-button" 
                onClick={() => {
                  modalChange(!modalOpen);
                  setFaqId(null);
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FaqModalForm;
