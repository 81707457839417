import React, { useState, useEffect } from "react";
import ServiceCard from "../../../components/Cards/ServiceCard/ServiceCard";
import * as servicesApi from "../../../utils/servicesApi";
import "./our-services.css";

export default function OurServices() {
  const [services, setServices] = useState();

  const getAllServices = async () => {
    try {
      const allServices = await servicesApi.getServices();
      setServices(allServices);
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <>
      <div className="services-container">
        <h1 className="services-title">OUR SERVICES</h1>
        <div className="services-cards-container">
          {services &&
            services.map((s) => (
              <div className="services-card-container">
                <ServiceCard
                  title={s.title}
                  image={s.img}
                  serviceLink={s.linkToJane}
                  leftDesc={s.desc}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
