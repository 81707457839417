import "./RatesAdminComponent.css";

export default function RatesAdminComponent({ title, price, is_hst }) {
  return (
    <>
      <div className="admin-rate-component-info">
        <div className="admin-rate-component-field-container">
          <div className="admin-rate-field-title">Rate Title:</div>
          <div>{title}</div>
        </div>
        <div className="admin-rate-component-rate-hst-container">
          <div className="admin-rate-component-field-container">
            <div className="admin-rate-field-title">Rate:</div>
            <div>${price}</div>
          </div>
          <div className="admin-rate-component-field-container">
            <div className="admin-rate-field-title">+ HST?</div>
            <div>{is_hst ? "Yes" : "No"}</div>
          </div>
        </div>
      </div>
    </>
  );
}
