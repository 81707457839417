import React from "react";
import "./footer-items.css";

export default function HoursInfo() {
  return (
    <>
      <div className="hours-info">
        <div className="hours-items">
          <h4 className="hours-header"> Hours of Operation</h4>
          <div className="hours">
            <div className="hours-date">Mon - Thurs:</div>
            <nbsp />
            <div className="hours-time">9am - 8pm</div>
          </div>
          <div className="hours">
            <div className="hours-date">Friday:</div>
            <div className="hours-time">9am - 6pm</div>
          </div>
          <div className="hours">
            <div className="hours-date">Saturday:</div>
            <div className="hours-time">9am - 3pm</div>
          </div>
          <div className="hours">
            <div className="hours-date">Sunday:</div>
            <div className="hours-time">closed</div>
          </div>
        </div>
      </div>
    </>
  );
}
