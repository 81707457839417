import React, { useState } from "react";
import { ImageFireBase, deleteImageFireBase } from "../../utils/Service/firebase";
import { postStaff } from "../../utils/ourTeamApi";
import { editStaff } from "../../utils/ourTeamApi";

import "./TeamMemberModalForm.css";

const TeamMemberModalForm = ({
  modalChange,
  modalOpen,
  teamMemberId,
  setTeamMemberId,
  teamMembers,
}) => {
  let teamMemberEdit = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    linkToJane: "",
    linkToCred: "",
  };
  let teamMemberImgEdit = null;

  if (teamMemberId) {
    let teamMemberFound = teamMembers.find(
      (teamMember) => teamMember._id === teamMemberId
    );
    teamMemberEdit = {
      firstName: teamMemberFound.firstName,
      lastName: teamMemberFound.lastName,
      jobTitle: teamMemberFound.jobTitle,
      linkToJane: teamMemberFound.linkToJane,
      linkToCred: teamMemberFound.linkToCred,
    };
    teamMemberImgEdit = teamMemberFound.img;
  }

  const [img, setImg] = useState(teamMemberImgEdit);
  const [formData, setFormData] = useState(teamMemberEdit);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null)
  const { firstName, lastName, jobTitle, linkToJane, linkToCred } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleFileUpload = async (e) => {
    const img = await ImageFireBase(e.target.files[0]);
    setImg(img);
    setImageToBeDeleted(null);
  };

  const handleDeleteFile = async (e) => {
    setImageToBeDeleted(img)
    document.getElementById('img').value = "";
    setImg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (imageToBeDeleted) await deleteImageFireBase(imageToBeDeleted); 

    const data = {
      firstName,
      lastName,
      jobTitle,
      linkToJane,
      linkToCred,
      img,
    };
    if (teamMemberId === null) {
      await postStaff(data);
      alert("Team Member Create Successful!");
    } else {
      await editStaff(teamMemberId, data);
      alert("Team Member Update Successful!");
    }
    setFormData({
      firstName: "",
      lastName: "",
      jobTitle: "",
      linkToJane: "",
      linkToCred: "",
    });
    setImg(null);
    modalChange(!modalOpen);
  };

  return (
    <div className="admin-modal-team">
      <div className="modal-team-content-container">
        <div
          className="modal-admin-team-close-form"
          onClick={() => modalChange(!modalOpen)}
        >
          <div className="modal-x">X</div>
        </div>
        {teamMemberId === null ? (
          <span className="modal-team-title">Add New Team Member</span>
        ) : (
          <span className="modal-team-title">Edit Team Member</span>
        )}
        <form action="" className="team__form" onSubmit={handleSubmit}>
          <div className="modal-admin-team-img-container">
            {img ? (
              <img className="modal-admin-team-img" src={img} alt="" />
            ) : (
              <div className="modal-admin-team-upload-text-container">
                Upload photo
              </div>
            )}
          </div>
          <div className="modal-admin-team-manage-file-container">
            <div className="modal-admin-team-choose-file-container">
              <input
                type="file"
                name="img"
                id="img"
                accept="image/*"
                onChange={(e) => handleFileUpload(e)}
                style={{ marginBottom: "10px" }}
              />
            </div>
            <div className="modal-admin-user-delete-button-container">
              <button type="button" onClick={(e) => handleDeleteFile(e)}>
                Delete File
              </button>
            </div>
          </div>
          <div className="modal-admin-team-input-container">
            <div>
              <input
                className="modal-admin-team-input-field"
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className="modal-admin-team-input-field"
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className="modal-admin-team-input-field"
                type="text"
                name="jobTitle"
                id="jobTitle"
                placeholder="Job Title"
                value={jobTitle}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className="modal-admin-team-input-field"
                type="url"
                pattern="https?://.+"
                name="linkToJane"
                id="linkToJane"
                placeholder="Booking Link"
                value={linkToJane}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className="modal-admin-team-input-field"
                type="url"
                pattern="https?://.+"
                name="linkToCred"
                id="linkToCred"
                placeholder="link to Cred"
                value={linkToCred}
                onChange={handleChange}
                required
              />
            </div>
            <div className="modal-admin-team-buttons-container">
              <button className="modal-admin-team-button" type="submit">
                {!teamMemberId ? "Add Team Member" : "Edit Team Member"}
              </button>
              <button
                className="modal-admin-team-button"
                onClick={() => {
                  modalChange(!modalOpen);
                  setTeamMemberId(null);
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeamMemberModalForm;
