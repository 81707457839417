import React, { useEffect, useState } from "react";
import hero from "../../images/products/PEWI-225 1.webp";
import "./product.css";
import ProductCard from "../../components/Cards/ProductCard/ProductCard";

import { getProduct } from "../../utils/product";

// import Scroll from "../../components/scroll/scroll";

const Products = () => {
  const [product, setProduct] = useState([]);
  useEffect(function () {
    const getAllProducts = async () => {
      const products = await getProduct();
      setProduct(products);
    };
    getAllProducts();
  }, []);

  return (
    <div className="product__page">
      <div className="product__img">
        <img src={hero} alt="" className="hero__img" />
      </div>
      <div className="product-page-content-container">
        <div>
          <h1 className="product__title">OUR PRODUCTS</h1>
        </div>
        <div className="product-subtitle-container">
          <span className="product__subtitle">
            For more information and price on products, please contact us directly
          </span>
        </div>
        <div className="product__cardGroup">
          {product.map((p) => (
            <div className="product__card">
              <ProductCard title={p.name} desc={p.desc} image={p.img} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
