const BASE_URL = '/api/users';

export async function getAllUsers() {
  return sendRequest(BASE_URL, 'GET')
} 

export function addUser(userData) {
  return sendRequest(`${BASE_URL}/add`, 'POST', userData);
}

export function updateUser(id, userData) {
  return sendRequest(`${BASE_URL}/update/${id}`, 'PUT', userData);
}

export function deleteUser(id) {
  return sendRequest(`${BASE_URL}/delete/${id}`, 'DELETE');
}

export function signUp(userData) {
  return sendRequest(`${BASE_URL}/signup`, 'POST', userData);
}

export function login(credentials) {
  return sendRequest(`${BASE_URL}/login`, 'POST', credentials);
}

/*--- Helper Functions ---*/

async function sendRequest(url, method = 'GET', payload = null) {
  // Fetch takes an optional options object as the 2nd argument
  // used to include a data payload, set headers, etc. 
  const options = { method };
  if (payload) {
    options.headers = { 'Content-Type': 'application/json' };
    options.body = JSON.stringify(payload);
  }
  const res = await fetch(url, options);
  // res.ok will be false if the status code set to 4xx in the controller action
  if (res.ok) return res.json();
  throw new Error('Bad Request');
}