import React, { useState, useEffect } from "react";
import ConditionCard from "../../../components/Cards/ConditionCard/ConditionCard";
import { getConditions } from "../../../utils/condition";

import "./conditions.css";

export default function ConditionsTreated() {
  const [condition, setConditon] = useState([]);
  const [tag, setTag] = useState("");

  //Set Pagination variables
  const [offset, setOffset] = useState(0);
  const conditionsPerPage = 6;

  // Handle showing conditons per page
  const listConditions = [];
  const page = offset * conditionsPerPage;
  for (let i = page; i < page + conditionsPerPage; i++) {
    if (i >= condition.length) {
      break;
    }
    listConditions.push(condition[i]);
  }

  //Handle Change Page
  const handleChangePage = (offset) => {
    setOffset(offset);
  };

  //Set Pagination
  const pagination = [];
  for (let i = 1; i <= Math.ceil(condition.length / conditionsPerPage); i++) {
    const page = <button onClick={() => handleChangePage(i - 1)}>{i}</button>;
    pagination.push(page);
  }

  //Get All Conditions
  useEffect(function () {
    const getAllConditions = async () => {
      const conditions = await getConditions();
      setConditon(conditions);
    };
    getAllConditions();
  }, []);

  //Search Condition by tag
  useEffect(
    function () {
      const searchCondition = async () => {
        const condition = await getConditions({ tag: tag });
        setConditon(condition);
        setOffset(0);
      };
      searchCondition();
    },
    [tag]
  );

  return (
    <div className="conditions_page-container">
      <div className="contitions-content-container">
        <div>
          <h1 className="condition__title">CONDITIONS TREATED</h1>
        </div>
        <div className="condition__search">
          <input
            className="search__input"
            type="text"
            placeholder="Search for conditions treated"
            onChange={(e) => setTag(e.target.value.toLowerCase())}
            value={tag}
          />
        </div>
        <div className="condition__cardGroup">
          {listConditions.map((p) => (
            <div className="condition-card-container">
              <ConditionCard title={p.name} leftDesc={p.desc} image={p.img} />
            </div>
          ))}
        </div>
        <div className="pagination">{pagination}</div>
      </div>
    </div>
  );
}
