import React, { useState, useEffect } from "react";
import "./Admin-faq.css";

import { getFaqsInfo } from "../../../utils/faqApi";
import { deleteFaq } from "../../../utils/faqApi";
import AdminFaqComp from "../../../components/admincomponents/AdminFaqComp/AdminFaqComp";
import FaqModalForm from "../../../components/modalForm/FaqModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";

const AdminFaq = (user) => {
  const [faqs, setFaqs] = useState([]);
  const [faqId, setFaqId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  //Faq Admin Page
  const getAllFaq = async () => {
    const faq = await getFaqsInfo();
    setFaqs(faq);
  };

  useEffect(() => {
    getAllFaq();
  }, []);

  // Delete a Faq
  const handleDelete = async (id) => {
    await deleteFaq(id);
    getAllFaq();
  };

  const modalChange = (val) => {
    setModalOpen(val);
    setDeleteModalOpen(val);
    getAllFaq();
    setFaqId(null);
  };

  return (
    <div id="admin-faq-frame">
      <div id="admin-faq-container">
        <div className="admin-faq-title-add-faq-container">
          <div className="admin-faq-title-wrapper">
            <span id="faq-list-title">List of FAQ's</span>
          </div>
          <div>
            <button
              className="admin-faq-add-button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              Add New FAQ
            </button>
          </div>
        </div>
        <div className="faq__layout">
          <div className="faq-info-container">
            {faqs &&
              faqs.map((f) => (
                <AdminFaqComp
                  quest={f.question}
                  ans={f.answer}
                  faqId={f._id}
                  key={f._id}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  deleteModalOpen={deleteModalOpen}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setFaqId={setFaqId}
                  handleDelete={handleDelete}
                  user={user}
                />
              ))}
          </div>
          <div>
            {modalOpen && (
              <FaqModalForm
                modalChange={modalChange}
                modalOpen={modalOpen}
                faqId={faqId}
                setFaqId={setFaqId}
                faqs={faqs}
              />
            )}
            {deleteModalOpen && (
              <DeleteModalForm
                modalChange={modalChange}
                deleteModalOpen={deleteModalOpen}
                title={{ title: "FAQ" }}
                itemId={faqId}
                setItemId={setFaqId}
                deleteItem={deleteFaq}
                items={faqs}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFaq;
