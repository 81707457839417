import React, { useState, useEffect } from "react";
import "./AdminConditions.css";
import { getConditions } from "../../../utils/condition";
import { deleteCondition } from "../../../utils/condition";
import ConditionModalForm from "../../../components/modalForm/ConditionModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const AdminCondtions = () => {
  const [condition, setCondition] = useState([]);
  const [conditionId, setConditionId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  //Condition Admin Page
  const getAllConditions = async () => {
    const condition = await getConditions();
    setCondition(condition);
  };

  useEffect(() => {
    getAllConditions();
  }, []);

  //Delete a Condition
  // const handleDelete = async (id) => {
  //   await deleteCondition(id);
  //   getAllConditions();
  //   setConditionId(null);
  // };

  const modalChange = (val) => {
    setDeleteModalOpen(val);
    setModalOpen(val);
    getAllConditions();
    setConditionId(null);
  };

  console.log(condition);

  return (
    <div className="condition__page">
      <div className="admin-condition-container">
        <div className="admin__condition__title">
          <div id="condition-list-title">List of Conditions</div>
          <div>
            <button
              onClick={() => setModalOpen(!modalOpen)}
              className="condition__btn"
            >
              Add Condition
            </button>
          </div>
        </div>
        <div className="condition__layout">
          <div className="condition-grid-container">
            {condition.map((c) => (
              <div className="condition__list">
                <div className="condition-picture-container">
                  {c.img ? (
                    <div>
                      <img src={c.img} alt="" className="condition__sm__img" />
                    </div>
                  ) : (
                    <div className="condition-no-picture">
                      <span>No Photo</span>
                    </div>
                  )}
                </div>
                <div className="condition__group">
                  <div className="condition__nameCard">{c.name}</div>
                  <div className="admin-condition-buttons">
                    <button
                      className="admin-condition-component-btn admin-condition-edit-btn"
                      onClick={() => {
                        setModalOpen(!modalOpen);
                        setConditionId(c._id);
                      }}
                    >
                      <FiEdit2 size={"20px"} />
                    </button>
                    {/* <button
                      className="admin-condition-component-btn admin-condition-del-btn"
                      onClick={() => handleDelete(c._id)}
                    >
                      <RiDeleteBin6Line size={"20px"} />
                    </button> */}
                    <button
                      className="component-btn del-btn"
                      onClick={() => {
                        setDeleteModalOpen(!deleteModalOpen);
                        setConditionId(c._id);
                      }}
                    >
                      <RiDeleteBin6Line size={"20px"} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {modalOpen && (
              <ConditionModalForm
                modalChange={modalChange}
                modalOpen={modalOpen}
                conditionId={conditionId}
                setConditionId={setConditionId}
                conditions={condition}
              />
            )}
            {deleteModalOpen && (
              <DeleteModalForm
                modalChange={modalChange}
                deleteModalOpen={deleteModalOpen}
                title={{ title: "condition" }}
                itemId={conditionId}
                setItemId={setConditionId}
                deleteItem={deleteCondition}
                items={condition}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCondtions;
