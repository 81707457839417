import React from "react";
import "./AdminTopBar.css";
import logo from "../../../images/logo/proLogo.png";
import { logOut } from "../../../utils/usersServices";

const AdminTopBar = ({ user, setUser }) => {
  //LOGOUT FUNCTION
  function handleLogOut() {
    logOut();
    setUser(null);
  }

  return (
    <div id="topbaradmin">
      <div id="left-topbar">
          <img className="topbar-logo" src={logo} alt="pro-evolution-logo" />
      </div>

      <div id="right-topbar">
        {user.image ? 
          <img className="topbar-user-image" src={user.image} alt="pro-evolution user" />
          :
          <div className="topbar-no-avatar"><span>No Photo</span></div>
        }
        <div className="topbar-username">{user.userName}</div>
        <div className="topbar-logout-container" style={{ cursor: "pointer" }} onClick={handleLogOut}>
            Logout
        </div>
        {/* <div style={{ cursor: "pointer" }} onClick={handleLogOut}>
          logout
        </div> */}
      </div>
    </div>
  );
};

export default AdminTopBar;
