import React, { useState, useEffect } from "react";
import "./AdminServices.css";
import { getServices } from "../../../utils/servicesApi";
import { deleteService } from "../../../utils/servicesApi";
import ServiceModalForm from "../../../components/modalForm/ServiceModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const AdminServices = () => {
  const [service, setService] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  //Service Admin Page
  const getAllServices = async () => {
    const service = await getServices();
    setService(service);
  };

  useEffect(() => {
    getAllServices();
  }, []);

  // Delete a Service
  // const handleDelete = async (id) => {
  //   await deleteService(id);
  //   alert("Service Successful Delete!");
  //   getAllServices();
  //   setServiceId(null);
  // };

  const modalChange = (val) => {
    setModalOpen(val);
    setDeleteModalOpen(val);
    getAllServices();
    setServiceId(null);
  };
  console.log(service);

  return (
    <>
      <div className="services-page-container">
        <div className="services__page">
          <div className="admin-services-title-add-member-container">
            <div className="admin-services-title-wrapper">
              <span className="admin-services-members-title">
                List of Services
              </span>
            </div>
            <div>
              <button
                className="admin-services-add-button"
                onClick={() => setModalOpen(!modalOpen)}
                style={{ marginLeft: "20px" }}
              >
                Add Service
              </button>
            </div>
          </div>
          <div className="services__layout">
            <div className="services-grid-container">
              {service.map((s) => (
                <div className="services__list">
                  {s.img ? (
                    <div className="services-picture-container">
                      <img src={s.img} alt="" className="services__sm__img" />
                    </div>
                  ) : (
                    <div className="services-no-picture">
                      <span>No Photo</span>
                    </div>
                  )}
                  <div className="services__group">
                    <div className="services-fields-container">
                      <div className="services-field-container">
                        <div className="services-field-title">Title:</div>
                        <div className="services-field-content">{s.title}</div>
                      </div>
                      <div className="services-field-container">
                        <div className="services-field-title">Description:</div>
                        <div className="services-field-content">{s.desc}</div>
                      </div>
                      <div className="services-field-container">
                        <div className="services-field-title">
                          Booking Link:
                        </div>
                        <div className="services-field-content">
                          {s.linkToJane}
                        </div>
                      </div>
                    </div>
                    <div className="admin-services-buttons-container">
                      <button
                        className="admin-services-component-btn admin-services-edit-btn"
                        onClick={() => {
                          setModalOpen(!modalOpen);
                          setServiceId(s._id);
                        }}
                      >
                        <FiEdit2 size={"15px"} />
                      </button>
                      {/* <button
                        className="component-btn admin-services-del-btn"
                        onClick={() => handleDelete(s._id)}
                      >
                        <RiDeleteBin6Line size={"15px"} />
                      </button> */}
                      <button
                        className="component-btn del-btn"
                        onClick={() => {
                          setDeleteModalOpen(!deleteModalOpen);
                          setServiceId(s._id);
                        }}
                      >
                        <RiDeleteBin6Line size={"15px"} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {modalOpen && (
                <ServiceModalForm
                  modalChange={modalChange}
                  modalOpen={modalOpen}
                  serviceId={serviceId}
                  setServiceId={setServiceId}
                  services={service}
                />
              )}
              {deleteModalOpen && (
                <DeleteModalForm
                  modalChange={modalChange}
                  deleteModalOpen={deleteModalOpen}
                  title={{ title: "service" }}
                  itemId={serviceId}
                  setItemId={setServiceId}
                  deleteItem={deleteService}
                  items={service}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminServices;
