import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import "./AdminUserComp.css";

const AdminUserComp = ({
  user,
  adminUser,
  handleDelete,
  setModalOpen,
  setDeleteModalOpen,
  deleteModalOpen,
  setAdminUserId,
  modalOpen,
}) => {
  return (
    <div id="user-container">
      <div id="user-user-wrapper">
        <div className="user-avatar-container">
          {adminUser.image ? (
            <img
              id="user-avatar"
              src={adminUser.image}
              alt="pro-evolution-user"
            />
          ) : (
            <div className="no-avatar">
              <span>No Photo</span>
            </div>
          )}
        </div>
        <div className="username-email-container">
          <div className="username-container">
            <span>{adminUser.userName}</span>
          </div>
          <div className="email-container">
            <span>{adminUser.email}</span>
          </div>
          <div className="isAdmin-container">
            <span>{adminUser.isAdmin ? "Manager" : "Associate"}</span>
          </div>
        </div>
        <div className="LoggedIn-container">
          <span>
            {adminUser._id === user.user._id ? "You are logged in" : ""}
          </span>
        </div>
      </div>
      <div id="button-wrapper">
        <button
          id="editButton"
          onClick={() => {
            setModalOpen(!modalOpen);
            setAdminUserId(adminUser._id);
          }}
          disabled={!user.user.isAdmin ? "true" : ""}
          className={!user.user.isAdmin ? "disabled-button" : ""}
        >
          <FiEdit2 size={"23px"} />
        </button>
        {/* <button id='deleteButton' 
                onClick={() => handleDelete(adminUser._id)} 
                disabled = {adminUser._id === user.user._id || !user.user.isAdmin ? "true" : ""} 
                className={adminUser._id === user.user._id || !user.user.isAdmin ? "disabled-button" : ""}
              >
                <RiDeleteBin6Line size={"23px"}/>
              </button>             */}
        <button
          id="deleteButton"
          onClick={() => {
            setDeleteModalOpen(!deleteModalOpen);
            setAdminUserId(adminUser._id);
          }}
          disabled={
            adminUser._id === user.user._id || !user.user.isAdmin ? "true" : ""
          }
          className={
            adminUser._id === user.user._id || !user.user.isAdmin
              ? "disabled-button"
              : ""
          }
        >
          <RiDeleteBin6Line size={"23px"} />
        </button>
      </div>
    </div>
  );
};

export default AdminUserComp;
