import React, { useState } from "react";
import { ImageFireBase, deleteImageFireBase } from "../../utils/Service/firebase";
import { postCondition } from "../../utils/condition";
import { editCondition } from "../../utils/condition";
import "./ConditionModalForm.css";

const ConditionModalForm = ({
  modalChange,
  modalOpen,
  conditionId,
  setConditionId,
  conditions,
}) => {
  let conditionEdit = { name: "", desc: "" };
  let conditionTagEdit = [];
  let conditionImgEdit = null;

  if (conditionId) {
    let conditionFound = conditions.find(
      (condition) => condition._id === conditionId
    );
    conditionEdit = {
      name: conditionFound.name,
      desc: conditionFound.desc,
    };
    conditionTagEdit = conditionFound.tag;
    conditionImgEdit = conditionFound.img;
  }

  const [img, setImg] = useState(conditionImgEdit);
  const [tag, setTag] = useState(conditionTagEdit);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null)
  const [formData, setFormData] = useState(conditionEdit);
  const { name, desc } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleDeleteFile = async (e) => {
    setImageToBeDeleted(img)
    document.getElementById('img').value = "";
    setImg("");
  }

  const handleFileUpload = async (e) => {
    const img = await ImageFireBase(e.target.files[0]);
    setImg(img);
    setImageToBeDeleted(null);
  };

  const handleTagChange = (e) => {
    const tagString = e.target.value.split(",");
    const cleanTag = tagString.map((t) => (t = t.trim()));
    setTag(cleanTag);
  };
  console.log(tag);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageToBeDeleted) await deleteImageFireBase(imageToBeDeleted);
    
    const data = {
      name,
      desc,
      img,
      tag,
    };
    if (conditionId === null) {
      await postCondition(data);
      alert("Condition Create Successful!");
    } else {
      await editCondition(conditionId, data);
      alert("Condition Update Successful!");
    }
    setFormData({ name: "", desc: "" });
    setImg(null);
    setTag([]);
    modalChange(!modalOpen);
  };

  return (
    <div className="modal-admin__condition">
      <div
        className="modal-admin-team-close-form"
        onClick={() => modalChange(!modalOpen)}
      >
        <div className="modal-x">X</div>
      </div>
      <div>
        {conditionId === null ? (
          <span className="modal-condition-title">Add New Condition</span>
        ) : (
          <span className="modal-condition-title">Edit Condition</span>
        )}
      </div>
      <div>
        <form action="" onSubmit={handleSubmit}>
          <div className="condition__section">
            <div className="admin-condition-img-with-choose-delete-file-container">
              <div className="modal-admin-condition-img-section">
                {img ? (
                  <img className="condition__modal__img" src={img} alt="" />
                ) : (
                  <div>Upload photo</div>
                )}
              </div>
              <div className="modal-admin-condition-manage-file-container">
                <div className="modal-admin-condition-choose-file-container">
                    <input
                      type="file"
                      name="img"
                      id="img"
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e)}
                      style={{ marginBottom: "10px" }}
                    />
                </div>
                <div className="modal-admin-condition-delete-button-container">
                    <button type="button" onClick={(e) => handleDeleteFile(e)}>
                      Delete File
                    </button>
                </div>
              </div>
            </div>
            <div>
              <div>
                <input
                  className="condition__name__input"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <textarea
                  className="condition__name__input"
                  type="text"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  value={desc}
                  onChange={handleChange}
                  style={{ fontSize: 16, fontWeight: 700, height: "240px", textAlign: "left" }}
                  required
                />
              </div>
              <div>
                <input
                  className="condition__name__input"
                  type="text"
                  name="tag"
                  id="tag"
                  placeholder="Tags"
                  value={tag}
                  onChange={(e) => handleTagChange(e)}
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <button type="submit" className="condition__modal__btn">
                {!conditionId ? "Add Condition" : "Edit Condition"}
              </button>
              <button
                onClick={() => {
                  modalChange(!modalOpen);
                  setConditionId(null);
                }}
                className="condition__modal__btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConditionModalForm;
