import React from "react";
import { NavLink } from "react-router-dom";
import "./link.css";

export function Link({ title, destination, isActive, isHLeftAliged=false }) {
  return (
    <>
      <NavLink
        to={destination}
        style={(isActive) => ({
          color: isActive ? "#ffffff" : "#D9D9D9",
          justifyContent: isHLeftAliged ? "flex-start" : null,
        })}
        className="nav-link"
      >
        {title}
      </NavLink>
    </>
  );
}
