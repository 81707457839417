import React from "react";
import scrollImg from "./scrollImg.png";

import "./scroll.css";

const Scroll = () => {
  return (
    <a href="#top" className="scroll">
      <img src={scrollImg} alt="" />
      <p className="scroll__text">Back To Top</p>
    </a>
  );
};

export default Scroll;
