import React from "react";

import Button from "../button/button";
import { Link } from "../link/link";
import { NavLink } from "react-router-dom";

import logo from "./images/logo_home.webp";
// NavBar CSS
import "./navbar.css";

export default function Navbar() {

  function handleOnClick() {
    let y = document.getElementById("MyMenuIcon")
    y.classList.toggle("change")

    let x = document.getElementById("MyTopNav")
    console.log(x)
    if (x.className === "nav-subheader-items-container") {
        console.log("yes")
        x.className += " responsive";
    } else {
        console.log("no")
        x.className = "nav-subheader-items-container";
    }
  }

  return (
    <>
      <div className="nav-header">
        <div className="nav-logo-container">
          <img src={logo} alt="pro-evo-logo" className="nav-logo" />
        </div>

        <div className="nav-btn-container">
          <Button
            title="Book Appointment"
            href="https://proevolutionwellness.janeapp.com/"
          />
        </div>
        <div className="nav-links-container">
          <div className="menu-icon-container">
            <NavLink onClick={handleOnClick}>
                <div className="menu-icon" id="MyMenuIcon">
                    <div className="menu-icon-bar1"></div>
                    <div className="menu-icon-bar2"></div>
                    <div className="menu-icon-bar3"></div>
                </div>
            </NavLink>
          </div>
        </div>
      </div>
      <nav className="nav-subheader-container">
        <div className="nav-subheader">
          <div className="nav-subheader-items-container" id="MyTopNav">
            <li className="nav-subheader-item-container">
              <div className="item-title">
                <Link title="Home" destination="/" className="item-title-link"/>
              </div>
            </li>
            <li className="nav-subheader-item-container">
              <div className="dropdown">
                <div className="item-title">
                  <span className="item-title-name">About Us</span>
                </div>
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <div className="dropdown-item-title">
                      <Link title="Our Process" destination="/our-process" isHLeftAliged={true} />
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div className="dropdown-item-title">
                      <Link title="Our Team" destination="/our-team" isHLeftAliged={true} />
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div className="dropdown-item-title">
                      <Link title="FAQ" destination="/faq" isHLeftAliged={true} />
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-subheader-item-container">
              <div className="dropdown">
                <div className="item-title">
                  <span className="item-title-name">Services</span>
                </div>
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <div className="dropdown-item-title">
                      <Link title="Our Services" destination="/our-services" isHLeftAliged={true} />
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div className="dropdown-item-title">
                      <Link title="Conditions Treated" destination="/conditions" isHLeftAliged={true} />
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-subheader-item-container">
              <div className="item-title">
                <Link title="Billing" destination="/rates" />
              </div>
            </li>
            <li className="nav-subheader-item-container">
              <div className="item-title">
                <Link title="Products" destination="/products" />
              </div>
            </li>
            <li className="nav-subheader-item-container">
              <div className="item-title">
                <Link title="Contact Us" destination="/contact-us" />
              </div>
            </li>
          </div>
        </div>
      </nav>
    </>
  );
}
