const BASE_URL = "/api/services";

export async function getServices() {
  return await fetch(`${BASE_URL}`, getServicesGet()).then((res) => res.json());
}

export async function postService(props) {
  return await fetch(`${BASE_URL}/create`, getServicePost(props)).then((res) =>
    res.json()
  );
}

//Edit a Service
export async function editService(id, data) {
  return await fetch(`${BASE_URL}/${id}`, getServicePost(data)).then((res) =>
    res.json()
  );
}

//Delete a Service
export async function deleteService(id) {
  return await fetch(`${BASE_URL}/${id}`, getServiceDelete()).then((res) =>
    res.json()
  );
}

function getServicesGet() {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    mode: "no-cors",
  };
}
function getServicePost({ title, img, desc, link }) {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ title, img, desc, link }),
  };
}

function getServiceDelete() {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
    },
  };
}
