import React,{useState} from "react";
import "./footer-items.css";

import ButtonMailto from "../mailto/mailto";
import AuthForm from "../auth/AuthForm";

import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
import { FaFax } from "react-icons/fa";

export default function BusinessInfo({setUser}) {
  const [authOpen,setAuthOpen] = useState(false)

  const authChange =(val)=>{
    setAuthOpen(val)
  }

  return (
    <>
      <div className="contact-info">
        <div className="contact-items">
          <div className="footer-icons">
            <FaMapMarkerAlt />
          </div>
          <div>
            3905 Major Mackenzie Drive West, Unit 116 Vaughan, ON, L4H 4J9
          </div>
        </div>
        <div className="contact-items">
          <div className="footer-icons">
            <FaPhoneAlt />
          </div>
          <div>905-303-0337</div>
        </div>
        <div className="contact-items">
          <div className="footer-icons">
            <FaFax />
          </div>
          <div>905-303-0397</div>
        </div>
        <div className="contact-items">
          <div className="footer-icons">
            <FaEnvelope />
          </div>
          <div className="mail-to">
            <ButtonMailto
              label="proevolutionwellness@gmail.com"
              mailto="mailto:proevolutionwellness@gmail.com"
            />
          </div>
        </div>
        <div id='admin-login' className="contact-items">
          <div className="footer-icons">
            <FaUserShield />
          </div>
          <div  onClick={()=>setAuthOpen(!authOpen)}>Admin Login</div>
         {authOpen && <AuthForm authChange={authChange} authOpen={authOpen} setUser={setUser} />}
        </div>
      </div>
    </>
  );
}
