import React, { useState, useEffect } from "react";
import "./AdminPrices.css";

import * as servicesApi from "../../../utils/servicesApi";
import * as ratesApi from "../../../utils/ratesApi";

import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import RatesAdminComponent from "../../../components/admincomponents/RatesAdminComponent/RatesAdminComponent";
import RateModalForm from "../../../components/modalForm/RatesModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";

const AdminPrices = () => {
  const [service, setService] = useState([]);
  const [currService, setCurrServiceId] = useState(null);
  const [currRate, setCurrentRateId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const getAllServices = async () => {
    const services = await servicesApi.getServices();
    setService(services);
  };

  useEffect(function () {
    getAllServices();
  }, []);

  // const handleDelete = async (currService, currRate) => {
  //   const currRateData = { rateId: currRate };
  //   await ratesApi.deleteRate(currService, currRateData);
  //   getAllServices();
  //   setCurrentRateId(null);
  // };

  const currRateData = { rateId: currRate };

  const modalChange = (val) => {
    setModalOpen(val);
    setDeleteModalOpen(val);
    getAllServices();
    setCurrServiceId(null);
  };

  return (
    <div className="prices-group">
      <div className="admin-prices-container">
        <div className="admin-prices-title-wrapper">
          <span id="admin-prices-list-title">List of Prices</span>
        </div>
        {service.map((s) => (
          <div className="services-list" key={s._id}>
            <div className="admin-prices-title-add-container">
              <div className="admin-prices-category">{s.title}</div>
              <div>
                <button
                  className="admin-prices-add-newRates"
                  onClick={() => {
                    setModalOpen(!modalOpen);
                    setCurrServiceId(s._id);
                  }}
                >
                  Add New Rate
                </button>
              </div>
            </div>
            {s.rates.map((r) => (
              <div className="admin-prices-item-card" key={r._id}>
                <RatesAdminComponent
                  title={r.name}
                  price={r.price.toFixed(2)}
                  is_hst={r.is_hst}
                />
                <div className="admin-prices-buttons">
                  <button
                    className="admin-prices-component-btn admin-prices-edit-btn"
                    key={s.id}
                    onClick={() => {
                      setCurrServiceId(s._id);
                      setCurrentRateId(r._id);
                      setModalOpen(!modalOpen);
                    }}
                  >
                    <FiEdit2 size={"15px"} />
                  </button>
                  {/* <button
                    className="admin-prices-component-btn admin-prices-del-btn"
                    key={s.id}
                    onClick={() => {
                      setCurrServiceId(s._id);
                      setCurrentRateId(r._id);
                      handleDelete(s._id, r._id);
                    }}
                  >
                    <RiDeleteBin6Line size={"15px"} />
                  </button> */}
                  <button
                    className="admin-prices-component-btn admin-prices-del-btn"
                    key={s.id}
                    onClick={() => {
                      setDeleteModalOpen(!deleteModalOpen);
                      setCurrServiceId(s._id);
                      setCurrentRateId(r._id);
                    }}
                  >
                    <RiDeleteBin6Line size={"15px"} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
        {modalOpen && (
          <RateModalForm
            modalChange={modalChange}
            modalOpen={modalOpen}
            serviceId={currService}
            serviceRateId={currRate}
            setCurrentRateId={setCurrentRateId}
            services={service}
          />
        )}
        {deleteModalOpen && (
          <DeleteModalForm
            modalChange={modalChange}
            deleteModalOpen={deleteModalOpen}
            title={{ title: "rate" }}
            itemId={currService}
            subItemId={currRateData}
            setItemId={setCurrServiceId}
            setSubItemId={setCurrentRateId}
            deleteItem={ratesApi.deleteRate}
            items={service}
          />
        )}
      </div>
    </div>
  );
};

export default AdminPrices;
