import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import "./contact-us.css";
import aboutUsPic from "../../images/contactUs/PEWI-184 1.webp";

export default function ContactUs() {
  return (
    <>
      <div id="c-u-container">
        <img id="c-u-pic" src={aboutUsPic} alt="proevolution-about-us" />
        <div className="c-u-info-container-mobile">
          <span className="c-u-info-title-mobile">CONTACT US</span>
          <table className="c-u-info-table-mobile">
            <tbody>
              <tr className="c-u-info-tr-mobile">
                <th className="c-u-info-th-mobile">Email:</th>
                <td className="c-u-info-td-mobile">proevolutionwellness@gmail.com</td>
              </tr>
              <tr className="c-u-info-tr-mobile">
                <th className="c-u-info-th-mobile">Phone:</th>
                <td className="c-u-info-td-mobile">905-303-0337</td>
              </tr>
              <tr className="c-u-info-tr-mobile">
                <th className="c-u-info-th-mobile">Address:</th>
                <td className="c-u-info-td-mobile">3905 Major Mackenzie Drive West, <br /> Unit 116, Vaughan, ON, L4H 4J9</td>
              </tr>            
            </tbody>
          </table>
        </div>
        <div id="c-u-title-container">
          <h1 id="c-u-title">Have questions? <br></br> We're here to help!</h1>
          <p id="c-u-description">
            Please fill out the inquiry form and a member of our team will
            contact you within the next 2 business days.
          </p>
          <ContactForm />
        </div>

      {/* ---- our location section - contact us page ---- */}
        <div id="map-container">
          <h2 id="map-title">Our Location</h2>
          <div className="c-u-locations-map-container">
            <iframe
              title="proEvolution"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.594703490833!2d-79.56786758394337!3d43.84350097911519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2531610a3123%3A0xbb1dcdb52405cb2b!2sPro%20Evolution%20Wellness!5e0!3m2!1sen!2sca!4v1678209951368!5m2!1sen!2sca"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="c-u-locations-map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
