import React from "react";
import Button from "../../button/button";
import "./TeamCard.css";

const TeamCard = ({ title, desc, image, link, serviceLink, leftDesc }) => {
  return (
    <div class="team-cardContainer" style={{ backgroundImage: `url("${image}")` }}>
      <div id="team-desc-container">
        <h1 id="team-title">{title}</h1>
        <p id="team-desc">{desc}</p>
        {leftDesc && <p id="team-desc__left">{leftDesc}</p>}
        {link && <Button title="Book Appointment" href={link} />}
      </div>
      <div id="team-title-container">
        <span id="team-title">{title}</span>
        <span id="team-desc">{desc}</span>
      </div>
      {link && (
        <div className="team-booking-btn-container">
            <Button title="Book Appointment" href={link} />
        </div>
      )}
      {serviceLink && (
        <div className="service__btn">
          <Button title="Book Appointment" href={serviceLink} />
        </div>
      )}
    </div>
  );
};

export default TeamCard;
