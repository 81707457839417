import React from "react";

import { Link } from "../link/link";

export default function SiteNavigation() {
  return (
    <>
      <div className="site-nav">
        <h4 className="site-nav-header">Site Navigation</h4>
        <div className="site-nav-links">
          <div className="footer-site-navlink">
            <Link title="Home" destination="/" />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Our Process"
              destination="/our-process"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Our Team"
              destination="/our-team"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Our Services"
              destination="/our-services"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Conditions Treated"
              destination="/conditions"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Billing"
              destination="/rates"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Product"
              destination="/products"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="Contact Us"
              destination="/contact-us"
              className="footer-site-navlink"
            />
          </div>
          <div className="footer-site-navlink">
            <Link
              title="FAQ"
              destination="/faq"
              className="footer-site-navlink"
            />
          </div>
        </div>
      </div>
    </>
  );
}
