import React, { useState, useEffect } from "react";
import hero from "../../images/rates/PEWI-201 1.webp";
import insurance from "../../images/rates/insurance.png";
import * as servicesApi from "../../utils/servicesApi";
import "./rates.css";

export default function Rates() {
  const [service, setService] = useState([]);
  useEffect(function () {
    const getAllServices = async () => {
      const services = await servicesApi.getServices();
      setService(services);
    };
    getAllServices();
  }, []);

  // console.log(service[0].rates[0].is_hst);
  return (
    <div className="rates-page-container">
      <div className="hero-img-container">
        <img src={hero} alt="" className="rates-hero-img" />
      </div>
      <div className="rates">
        <div>
          <h1 className="rates__title">RATES</h1>
        </div>
        <div>
          <p className="rates__subtitle">
            Find our most recent rates for our services listed below
          </p>
        </div>
        <div className="rates-section">
          {service &&
            service.map((s) => (
              s.rates.length ?                 
              <div className="rates__treatment">
                  <div>
                    <div className="treatment__title">{s.title}</div>
                    <div>
                      {s.rates.map((r) => (
                        <div className="treatment__single">
                          <span className="treatment__name__dot">
                            <span className="treatment__name">
                              {r.name}
                              {" "}
                            </span>
                            <span className="treatment__dot">
                              {".".repeat(200)}
                            </span>
                          </span>
                          <span className="treatment__price">
                          {" "}
                          ${r.price.toFixed(2)}
                          {r.is_hst ? "+HST" : ""}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                :
                null
            ))}
        </div>
        <div>
          <h1 className="rates__title">DIRECT BILLING</h1>
          <p className="rates__subtitle">
            We bill directly to the following insurance providers:
          </p>
        </div>
        <div className="insurance-container">
          <img src={insurance} alt="" className="insurance" />
        </div>
      </div>
    </div>
  );
}
