import React, { useState, useEffect } from "react";
import "./AdminProducts.css";
import { getProduct } from "../../../utils/product";
import { deleteProduct } from "../../../utils/product";
import ModalForm from "../../../components/modalForm/ProductModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const AdminProducts = () => {
  const [product, setProduct] = useState([]);
  const [productId, setProductId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  //Prouct Admin Page
  const getAllConditions = async () => {
    const product = await getProduct();
    setProduct(product);
  };

  useEffect(() => {
    getAllConditions();
  }, []);

  // Delete a Product
  // const handleDelete = async (id) => {
  //   await deleteProduct(id);
  //   getAllConditions();
  //   setProductId(null);
  // };

  const modalChange = (val) => {
    setModalOpen(val);
    setDeleteModalOpen(val);
    getAllConditions();
    setProductId(null);
  };

  return (
    <div className="admin__product__page">
      <div className="admin-product-container">
        <div className="admin-products-title-add-container">
          <div className="admin-products-title-wrapper">
            <span id="admin-products-title">List of Products</span>
          </div>
          <div>
            <button
              onClick={() => setModalOpen(!modalOpen)}
              className="product__btn"
            >
              Add New Product
            </button>
          </div>
        </div>
        <div className="product__layout">
          <div className="product-grid-container">
            {product.map((p) => (
              <div className="product__list">
                <div className="product-picture-container">
                  {p.img ? (
                    <img src={p.img} className="product__img" alt="" />
                  ) : (
                    <div className="product-no-picture">
                      <span>No Photo</span>
                    </div>
                  )}
                </div>
                <div className="product__group">
                  <div className="product__nameCard">
                    <div className="team-field-title">Product Name:</div>
                    {p.name}
                  </div>
                  <div className="product__nameCard">
                    <div className="team-field-title">Product Description:</div>
                    {p.desc}
                  </div>
                  <div className="buttons">
                    <button
                      className="component-btn edit-btn"
                      onClick={() => {
                        setModalOpen(!modalOpen);
                        setProductId(p._id);
                      }}
                    >
                      <FiEdit2 size={"20px"} />
                    </button>
                    {/* <button
                      className="component-btn del-btn"
                      onClick={() => handleDelete(p._id)}
                    >
                      <RiDeleteBin6Line size={"20px"} />
                    </button> */}
                    <button
                      className="component-btn del-btn"
                      onClick={() => {
                        setDeleteModalOpen(!deleteModalOpen);
                        setProductId(p._id);
                      }}
                    >
                      <RiDeleteBin6Line size={"20px"} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {modalOpen && (
              <ModalForm
                modalChange={modalChange}
                modalOpen={modalOpen}
                productId={productId}
                setProductId={setProductId}
                products={product}
              />
            )}
            {deleteModalOpen && (
              <DeleteModalForm
                modalChange={modalChange}
                deleteModalOpen={deleteModalOpen}
                title={{ title: "product" }}
                itemId={productId}
                setItemId={setProductId}
                deleteItem={deleteProduct}
                items={product}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
