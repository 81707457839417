import React from "react";
import { deleteImageFireBase } from "../../utils/Service/firebase";
import "./DeleteModalForm.css";

const DeleteModalForm = ({
  modalChange,
  deleteModalOpen,
  title,
  itemId,
  subItemId,
  setItemId,
  setSubItemId,
  deleteItem,
  items, 
}) => {
  let itemFound = items.find((item) => item._id === itemId);

  const handleDelete = async (itemId, e, subItemId) => {
    e.preventDefault();

    if (title.title !== "rate") {
      if (itemFound.image) await deleteImageFireBase(itemFound.image);
      if (itemFound.img) await deleteImageFireBase(itemFound.img);
    }

    await deleteItem(itemId, subItemId ? subItemId : null);
    setItemId(null);
    subItemId ? setSubItemId(null) : console.log(""); //expression needed for this to work
    modalChange(!deleteModalOpen);
    alert("successful deleted!");
  };
  return (
    <div className="admin__delete">
      <div className="modal-admin-delete-container">
        <div
          className="modal-admin-delete-close-form"
          onClick={() => modalChange(!deleteModalOpen)}
        >
          <div className="modal-admin-delete-x">X</div>
        </div>

        <div className="modal-admin-delete-title-container">
          <h2>Are you sure you want to remove this {title.title}? </h2>
        </div>
        <form action="" className="delete__form">
          <div className="delete__modal__container">
            <div className="modal-admin-delete-buttons-container">
              <button
                className="modal-admin-delete-button"
                onClick={(e) =>
                  handleDelete(itemId, e, subItemId ? subItemId : null)
                }
              >
                Yes
              </button>
              <button
                className="modal-admin-delete-button"
                onClick={() => {
                  modalChange(!deleteModalOpen);
                  setItemId(null);
                }}
                style={{ marginLeft: "10px" }}
              >
                No
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteModalForm;
