import "./testimonial.css";
import { FaStar } from "react-icons/fa";
export default function TestimonialCard({ stars, desc, name }) {
  console.log(typeof stars);
  let star = (
    <FaStar className="testimonial-stars"/>
  );
  let starDisplay = [];
  for (let i = 0; i < stars; i++) {
    starDisplay.push(star);
  }
  console.log(starDisplay);
  return (
    <div className="testimonial-card">
      <div>{stars ? starDisplay : "Number of Stars"}</div>
      <div className="test-desc">{desc ? desc : "Review Description"}</div>
      <div className="test-name">{name ? name : "Name of Reviewer"}</div>
      <div className="testimonial-btns">
        <a
          href="https://www.google.com/search?q=pro+evolution+wellness&rlz=1C5CHFA_enCA1007CA1008&oq=pro+evolution&aqs=chrome.0.35i39j46i175i199i512j69i57j46i512l2j69i60l3.2137j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b2531610a3123:0xbb1dcdb52405cb2b,1,,,,"
          target="_blank"
          rel="noreferrer"
          referrerPolicy="no-referrer"
        >
          <button>
            <span>View All Reviews</span>
          </button>
        </a>
        <a
          href="https://www.google.com/search?q=pro+evolution+wellness&rlz=1C5CHFA_enCA1007CA1008&oq=pro+evolution&aqs=chrome.0.35i39j46i175i199i512j69i57j46i512l2j69i60l3.2137j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b2531610a3123:0xbb1dcdb52405cb2b,1,,,,"
          target="_blank"
          rel="noreferrer"
          referrerPolicy="no-referrer"
        >
          <button>
            <span>Write Review</span>
          </button>
        </a>
      </div>
    </div>
  );
}
