import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import "./ContactForm.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {

  const form = useRef();

  const notify = () => toast.success('Email Sent!', {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID, 
      process.env.REACT_APP_TEMPLATE_ID, 
      form.current, 
      process.env.REACT_APP_PUBLIC_KEY
    )
      .then((result) => {
        notify()
      }, (error) => {
          console.log(error.text);
      });
  }

  return (    
    <form ref={form} onSubmit={sendEmail}>
      <div>
      <ToastContainer />
      </div>
    <div id="c-u-form-container">
      <div id="text-input-container">
        <label htmlFor="" id="c-u-label">
          Name
        </label>
        <input type="text" className="contact__inputBox" name='name' required/>
      </div>
      <div id="text-input-container">
        <label htmlFor="" id="c-u-label">
          Phone No.
        </label>
        <input 
          type="tel" 
          pattern="[0-9]{0,3}[0-9]{3}[0-9]{3}[0-9]{4}"
          className="contact__inputBox"
          name='phone' 
          title='Phone no. must contain at least 10 digits'
        />
      </div>
      <div id="text-input-container">
        <label htmlFor="" id="c-u-label">
          Email
        </label>
        <input 
          type="email" 
          className="contact__inputBox" 
          name='email' 
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          title='Email must be separated by "@" and ".".  (E.g. characters@characters.domain)'
        />
      </div>

      <div id="text-input-container">
        <label id="c-u-label">Services</label>
        <div className="checkbox__group">
          <div id="check-container">
            <input id="c-u-checkbox" type="checkbox" name='chiropractic'/>
            <label id="c-u-checkbox-label">Chiropractic</label>
          </div>
          <div id="check-container">
            <input id="c-u-checkbox" type="checkbox"  name='physiotherapy'/>
            <label id="c-u-checkbox-label">Physiotherapy</label>
          </div>
          <div id="check-container">
            <input id="c-u-checkbox" type="checkbox" name='registered-massage-therapy'/>
            <label id="c-u-checkbox-label">Registered Message Therapy</label>
          </div>
          <div id="check-container">
            <input id="c-u-checkbox" type="checkbox" name='holistic-nutrition-consulting'/>
            <label id="c-u-checkbox-label">Holistic Nutrition Consulting</label>
          </div>
          <div id="check-container">
            <input id="c-u-checkbox" type="checkbox"  name='functional-rehab'/>
            <label id="c-u-checkbox-label">Functional Rehab</label>
          </div>
        </div>

        <div id="textarea-container">
          <label id="c-u-label">Message</label>
          <textarea 
            name="message" 
            rows="4" 
            cols="50" 
            id="c-u-textarea" 
            required
            title="Please enter a brief message in regards to your inquiry."
          />
        </div>
        <div className="contact-button-container">
          <button type='submit' id="c-u-button">Submit</button>
        </div>
      </div>
    </div>
    </form>
  )
}

export default ContactForm;
