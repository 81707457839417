import React, { useState } from "react";
import { ImageFireBase, deleteImageFireBase } from "../../utils/Service/firebase";
import { postService } from "../../utils/servicesApi";
import { editService } from "../../utils/servicesApi";

import "./ServiceModalForm.css";

const ModalForm = ({ modalChange, modalOpen, serviceId, setServiceId, services }) => {

  let serviceEdit = { name: "", desc: "", link: "",}
  let serviceImgEdit = null

  if (serviceId) {
    let serviceFound = services.find((service) => service._id === serviceId);
    serviceEdit = {
      name: serviceFound.title,
      desc: serviceFound.desc, 
      link: serviceFound.linkToJane, 
    }
    serviceImgEdit = serviceFound.img
  }

  const [img, setImg] = useState(serviceImgEdit);
  const [formData, setFormData] = useState(serviceEdit);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null)
  const { name, desc, link } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleFileUpload = async (e) => {
    setImg(e.target.files[0]);
    const img = await ImageFireBase(e.target.files[0]);
    setImg(img);
    setImageToBeDeleted(null);
  };

  const handleDeleteFile = async (e) => {
    setImageToBeDeleted(img)
    document.getElementById('img').value = "";
    setImg("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageToBeDeleted) await deleteImageFireBase(imageToBeDeleted);

    const data = {
      title: name,
      desc,
      img,
      link,
    };
    if (serviceId === null) {
      await postService(data);
      alert("Product Create Successful!");
    } else {
      await editService(serviceId, data);
      alert("Product Updated Successful!");
    }
    setFormData({
      name: "",
      desc: "",
      link: "",
    });
    setImg(null);
    modalChange(!modalOpen);
  };
  return (
    <div>
      <div className="modal-admin-service-container">
        <div className="modal-service-content-container">
          <div className='modal-admin-service-close-form' onClick={()=>modalChange(!modalOpen)}>
            <div className="modal-x">X</div>
          </div>
            {serviceId === null ? (
              <span className="modal-service-title">Add New Service</span>
            ) : (
              <span className="modal-service-title">Edit Service</span>
            )}
            <form action="" onSubmit={handleSubmit} className="service__form">
              <div className="modal-admin-service-img-container">
                {img ? (
                  <img className="modal-admin-service-img" src={img} alt="" />
                ) : (
                  <div className="modal-admin-service-upload-text-container">Upload photo</div>
                )}
              </div>
              <div className="modal-admin-service-manage-file-container">
                <div className="modal-admin-service-choose-file-container">
                    <input
                      type="file"
                      name="img"
                      id="img"
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e)}
                      style={{ marginBottom: "10px" }}
                    />
                </div>
                <div className="modal-admin-service-delete-button-container">
                  <button type="button" onClick={(e) => handleDeleteFile(e)}>
                    Delete File
                  </button>
                </div>
              </div>

              <div className="modal-admin-service-input-container">
                <div>
                  <input
                    className="modal-admin-service-input-field"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <textarea
                    className="modal-admin-service-textarea-field"
                    type="text"
                    name="desc"
                    id="desc"
                    placeholder="Description"
                    value={desc}
                    onChange={handleChange}
                    style={{ textAlign: "left", fontSize: 16, fontWeight: 700}}
                  />
                </div>
                <div>
                  <input
                    className="modal-admin-service-input-field"
                    type="text"
                    name="link"
                    id="link"
                    placeholder="Link"
                    value={link}
                    onChange={handleChange}
                  />
                </div>

                <div className="modal-admin-service-buttons-container">
                  <button className="modal-admin-service-button" type="submit" style={{ marginRight: "5px" }}>
                    {!serviceId ? "Add Service" : "Edit Service"}
                  </button>
                  <button
                    className="modal-admin-service-button" 
                    onClick={() => {
                      modalChange(!modalOpen);
                      setServiceId(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
