const BASE_URL = '/api/faqs';

// Get all faqs info
export async function getFaqsInfo() {
  return await fetch(`${BASE_URL}`, getOptionsGet()).then((res) => res.json());
}

//Create a faq
export async function postFaq(props) {
  return await fetch(`${BASE_URL}/create`, getOptionsPost(props)).then((res) => res.json());
}

//Edit a faq
export async function editFaq(id, data) {
  return await fetch(`${BASE_URL}/update/${id}`, getOptionsPut(data)).then((res) => res.json());
}

//Delete a faq
export async function deleteFaq(id) {
  return await fetch(`${BASE_URL}/delete/${id}`, getFaqDelete()).then((res) => res.json());
}

/*-- Helper Functions --*/

function getOptionsGet() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      mode:'no-cors'
  }
}

function getOptionsPost(data) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${getToken()}`
    }, 
    body: JSON.stringify(data),
  };
}

function getOptionsPut(data) {
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${getToken()}`
    }, 
    body: JSON.stringify(data),
  };
}

function getFaqDelete() {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}` //Customer has search function which use POST
    },
  };
}

