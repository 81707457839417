import React, { useState, useEffect } from "react";
import "./AdminTeamMembers.css";
import { getAllStaff } from "../../../utils/ourTeamApi";
import { deleteStaff } from "../../../utils/ourTeamApi";
import TeamMemberModalForm from "../../../components/modalForm/TeamMemberModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const AdminTeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMemberId, setTeamMemberId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Team Members Admin Page
  const getAllTeamMembers = async () => {
    const teamMembers = await getAllStaff();
    setTeamMembers(teamMembers);
  };

  useEffect(() => {
    getAllTeamMembers();
  }, []);

  const modalChange = (val) => {
    setModalOpen(val);
    setDeleteModalOpen(val);
    getAllTeamMembers();
    setTeamMemberId(null);
  };

  console.log(teamMembers);

  return (
    <>
      <div className="team-page-container">
        <div className="team__page">
          <div className="admin-team-title-add-member-container">
            <div className="admin-team-title-wrapper">
              <span className="admin-team-members-title">
                List of Team Members
              </span>
            </div>
            <div>
              <button
                className="admin-team-add-button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                Add Team Member
              </button>
            </div>
          </div>

          <div className="team__layout">
            <div className="team-grid-container">
              {teamMembers.map((t) => (
                <div className="team__list">
                  <div className="team-picture-container">
                    {t.img ? (
                      <img src={t.img} alt="" className="team__sm__img" />
                    ) : (
                      <div className="team-no-picture">
                        <span>No Photo</span>
                      </div>
                    )}
                  </div>
                  <div className="team__group">
                    <div className="team-fields-container">
                      <div className="team-field-container">
                        <div className="team-field-title">First Name:</div>
                        <div>{t.firstName}</div>
                      </div>
                      <div className="team-field-container">
                        <div className="team-field-title">Last Name:</div>
                        <div>{t.lastName}</div>
                      </div>
                      <div className="team-field-container">
                        <div className="team-field-title">Job Title:</div>
                        <div>{t.jobTitle}</div>
                      </div>
                      <div className="team-field-container">
                        <div className="team-field-title">Booking Link:</div>
                        <div>{t.linkToJane}</div>
                      </div>
                      <div className="team-field-container">
                        <div className="team-field-title">Link to Cred:</div>
                        <div>{t.linkToCred}</div>
                      </div>
                    </div>
                    <div style={{ marginTop: "0px" }}>
                      <button
                        className="admin-team-component-btn admin-team-edit-btn"
                        onClick={() => {
                          setModalOpen(!modalOpen);
                          setTeamMemberId(t._id);
                        }}
                      >
                        <FiEdit2 size={"15px"} />
                      </button>
                      {/* <button
                        className="admin-team-component-btn admin-team-del-btn"
                        onClick={() => handleDelete(t._id)}
                        style={{ marginLeft: "5px" }}
                      >
                        <RiDeleteBin6Line size={"15px"} />
                      </button> */}
                      <button
                        className="component-btn del-btn"
                        onClick={() => {
                          setDeleteModalOpen(!deleteModalOpen);
                          setTeamMemberId(t._id);
                        }}
                      >
                        <RiDeleteBin6Line size={"15px"} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {modalOpen && (
                <TeamMemberModalForm
                  modalChange={modalChange}
                  modalOpen={modalOpen}
                  teamMemberId={teamMemberId}
                  setTeamMemberId={setTeamMemberId}
                  teamMembers={teamMembers}
                />
              )}
              {deleteModalOpen && (
                <DeleteModalForm
                  modalChange={modalChange}
                  deleteModalOpen={deleteModalOpen}
                  title={{ title: "team member" }}
                  itemId={teamMemberId}
                  setItemId={setTeamMemberId}
                  deleteItem={deleteStaff}
                  items={teamMembers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTeamMembers;
