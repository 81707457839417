import React, { useState, useEffect } from "react";
import "./faq.css";
import mainPic from "../../../images/faq/PEWI-103 1.webp";
import FaqComp from "../../../components/faq/FaqComp";
import * as faqsAPI from "../../../utils/faqApi";

export default function Faq() {
  //USE STATE FAQS
  const [faqs, setFaqs] = useState();
  // const [error, setError] = useState();

  // //GET LIST OF ALL FAQS
  // async function getAllFaqs() {
  //   try {
  //     let faqsList = await faqsAPI.getFaqsInfo();
  //     setFaqs(faqsList);
  //   } catch (err) {
  //     setError(err);
  //     console.log(error)
  //   }
  // }

  //GET ALL FAQS FUNCTION
  useEffect(() => {

    //GET LIST OF ALL FAQS
    async function getAllFaqs() {
      try {
        let faqsList = await faqsAPI.getFaqsInfo();
        setFaqs(faqsList);
      } catch (err) {
        console.log(err)
        // setError(err);
        // console.log(error)
      }
    }

    getAllFaqs();
  }, []);

  return (
    <div className="faq-container">
      <img id="faq-main-image" src={mainPic} alt="pro-evolution-wellness-faq" />
      <div id="faq-info-container">
        <div id="faq-title">FREQUENTLY ASKED QUESTIONS</div>
        {faqs &&
          faqs.map((f) => (
            <FaqComp quest={f.question} ans={f.answer} key={f._id} />
          ))}
      </div>
    </div>
  );
}
