import React from "react";
import Button from "../../button/button";
import "./ServiceCard.css";

const ServiceCard = ({ title, desc, image, link, serviceLink, leftDesc }) => {
  return (
    <div class="service-card-container" style={{ backgroundImage: `url("${image}")` }}>
      <div id="service-desc-container">
        <h1 id="service-title">{title}</h1>
        {leftDesc && <p id="service-desc__left">{leftDesc}</p>}
        {link && <Button title="Book Appointment" href={link} />}
      </div>
      <div id="service-title-container">
        <h1 id="service-title">{title}</h1>
        <span id="service-learn-more">Learn More</span>
      </div>
      {serviceLink && (
        <div className="service__btn">
          <Button title="Book Appointment" href={serviceLink} />
        </div>
      )}
    </div>
  );
};

export default ServiceCard;
