import React, { useState } from "react";
import { ImageFireBase, deleteImageFireBase } from "../../utils/Service/firebase";
import { addUser } from "../../utils/usersServices";
import { updateUser } from "../../utils/usersServices";
import "./AdminUserModalForm.css";

const TeamMemberModalForm = ({
  modalChange,
  modalOpen,
  adminUserId,
  setAdminUserId,
  adminUsers,
  user, 
}) => {
  let adminUserEdit = { userName: "", email: "", isAdmin: false, password: "" };
  let adminUserImgEdit = null;

  if (adminUserId) {
    let adminUserFound = adminUsers.find(
      (adminUser) => adminUser._id === adminUserId
    );
    adminUserEdit = {
      userName: adminUserFound.userName,
      email: adminUserFound.email,
      isAdmin: adminUserFound.isAdmin,
      password: adminUserFound.password,
    };
    adminUserImgEdit = adminUserFound.image;
  }

  const [image, setImage] = useState(adminUserImgEdit);

  const [formData, setFormData] = useState(adminUserEdit);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null)
  const { userName, email, isAdmin, password } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleCheckboxToggle = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.checked,
    };
    setFormData(newFormData);
  };

  const handleFileUpload = async (e) => {
    const image = await ImageFireBase(e.target.files[0]);
    console.log("img: ");
    console.log(image);
    setImage(image);
    setImageToBeDeleted(null);
  };

  const handleDeleteFile = async (e) => {
    setImageToBeDeleted(image)
    document.getElementById('img').value = "";
    setImage("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageToBeDeleted) await deleteImageFireBase(imageToBeDeleted);

    const data = {
      userName,
      email,
      isAdmin,
      image,
      password,
    };
    console.log("inside handle submit: ");
    console.log(data);
    if (adminUserId === null) {
      await addUser(data);
      alert("User Create Successful!");
    } else {
      await updateUser(adminUserId, data);
      alert("User Update Successful!");
    }
    modalChange(!modalOpen);
    setFormData({ userName: "", email: "", isAdmin: false, password: "" });
    setImage(null);
  };

  return (
    <div className="admin__user">
      <div className="modal-container">
        <div className='modal-admin-user-close-form' onClick={()=>modalChange(!modalOpen)}>
          <div>X</div>
        </div>

        <div className="modal-admin-user-title-container">
          {adminUserId === null ? 
            <span className="modal-admin-user-title">Add New Admin</span> 
            : 
            <span className="modal-admin-user-title">Edit Profile</span>
          }
        </div>

        <form action="" className="admin_user-form" onSubmit={handleSubmit}>
          <div className="modal-admin-user-img-container">
            {image ? (
              <img className="modal-admin-user-img" src={image} alt="" />
            ) : (
              <div className="modal-admin-user-upload-text-container">Upload photo</div>
            )}
          </div>
          <div className="modal-admin-user-manage-file-container">
            <div className="modal-admin-user-choose-file-container">
                <input
                  className="modal-admin-user-choose-file-field"
                  type="file"
                  name="img"
                  id="img"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e)}
                  style={{ marginBottom: "10px" }}
                />
            </div>
            <div className="modal-admin-user-delete-button-container">
                <button type="button" onClick={(e) => handleDeleteFile(e)}>
                  Delete File
                </button>
            </div>
          </div>

          <div className="modal-admin-user-input-container">
            <div>
              <input
                className="modal-admin-user-input-field"
                type="text"
                name="userName"
                id="userName"
                placeholder="Username"
                value={userName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className="modal-admin-user-input-field"
                type="text"
                name="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                className="modal-admin-user-input-field"
                type="text"
                name="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="modal-admin-user-label" style={{ marginRight: "10px" }}>Admin?</label>
              <input
                className="modal-admin-user-checkbox-field"
                type="checkbox"
                name="isAdmin"
                id="isAdmin"
                value={isAdmin}
                onChange={handleCheckboxToggle}
                checked={isAdmin}
              />
            </div>
            <div className="modal-admin-user-buttons-container">
              <button className="modal-admin-user-button" type="submit">
                {!adminUserId ? "Add User" : "Edit User"}
              </button>
              <button
                className="modal-admin-user-button"
                onClick={() => {
                  modalChange(!modalOpen);
                  setAdminUserId(null);
                }}
                style={{ marginLeft: "10px" }}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeamMemberModalForm;
