import React, { useState, useEffect } from "react";
import "./AdminUser.css";
import AdminUserComp from "../../../components/admincomponents/admin-user-component/AdminUserComp";

import { getAllUsers } from "../../../utils/usersServices";
import { deleteUser } from "../../../utils/usersServices";
import AdminUserModalForm from "../../../components/modalForm/AdminUserModalForm";
import DeleteModalForm from "../../../components/modalForm/DeleteModalForm";

const AdminUser = (user, selected, getCurrentUsers) => {
  console.log(user);
  const [adminUsers, setAdminUsers] = useState([]);
  const [adminUserId, setAdminUserId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Users Admin Page
  const getAllAdminUsers = async () => {
    const adminUsers = await getAllUsers();
    setAdminUsers(adminUsers);
  };

  useEffect(() => {
    getAllAdminUsers();
  }, []);

  // Delete a User
  // const handleDelete = async (id) => {
  //   await deleteUser(id);
  //   getAllAdminUsers();
  // };

  const modalChange = (val) => {
    setModalOpen(val);
    setDeleteModalOpen(val);
    getAllAdminUsers();
    setAdminUserId(null);
  };

  console.log(adminUsers);

  return (
    <div id="admin-user-container">
      <div className="admin-wrapper">
        <div id="title-wrapper">
          <h1 id="user-list-title">Admins</h1>
        </div>
        <div className="admin-comp-container">
          {adminUsers.map((a) => (
            <AdminUserComp
              key={a.username}
              user={user}
              adminUser={a}
              // handleDelete={handleDelete}
              setModalOpen={setModalOpen}
              deleteModalOpen={deleteModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
              setAdminUserId={setAdminUserId}
              modalOpen={modalOpen}
            />
          ))}
          <div className="button-wrapper">
            <button
              className="button-admin"
              onClick={() => setModalOpen(!modalOpen)}
            >
              Add New Admin
            </button>
          </div>
          <div className="users__layout">
            <div className="users-grid-container">
              {modalOpen && (
                <AdminUserModalForm
                  modalChange={modalChange}
                  modalOpen={modalOpen}
                  adminUserId={adminUserId}
                  setAdminUserId={setAdminUserId}
                  adminUsers={adminUsers}
                  user={user}
                  getCurrentUsers={getCurrentUsers}
                />
              )}
              {deleteModalOpen && (
                <DeleteModalForm
                  modalChange={modalChange}
                  deleteModalOpen={deleteModalOpen}
                  title={{ title: "admin" }}
                  itemId={adminUserId}
                  setItemId={setAdminUserId}
                  deleteItem={deleteUser}
                  items={adminUsers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUser;
