import "./App.css";
import React, { useState } from "react";
import { getUser } from "./utils/usersServices";
import { Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.min.css';

// Pages Imports
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import OurProcess from "./pages/about-us/our-process/our-process";
import OurTeam from "./pages/about-us/our-team/our-team";
import Faq from "./pages/about-us/faq/faq";
import OurServices from "./pages/services/our-services/our-services";
import ConditionsTreated from "./pages/services/conditions/conditions";
import Rates from "./pages/rates/rates";
import ContactUs from "./pages/contact-us/contact-us";
import Products from "./pages/products/products";

import Scroll from "./components/scroll/scroll";

//ADMIN IMPORTS
import AdminTopBar from "./components/admincomponents/admin-topbar-component/AdminTopBar";
import AdminNav from "./components/admincomponents/adminnav/AdminNav";
import AdminConditions from "./pages/adminPages/admin-conditions/AdminCondtions";
import AdminPrices from "./pages/adminPages/admin-prices-conditions/AdminPrices";
import AdminFaq from "./pages/adminPages/admin-faq/Admin-faq";
import AdminProducts from "./pages/adminPages/admin-products/AdminProducts";
import AdminServices from "./pages/adminPages/admin-services/AdminServices";
import AdminTeamMembers from "./pages/adminPages/admin-team-members/AdminTeamMembers";
import AdminUser from "./pages/adminPages/admin-user/AdminUser";

function App() {
  const [user, setUser] = useState(getUser());
  const [selected, setSelected] = useState("");

  return (
    <div className="App">
      {!user ? (
        <header className="App-header">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-process" element={<OurProcess />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/our-services" element={<OurServices />} />
            <Route path="/conditions" element={<ConditionsTreated />} />
            <Route path="/rates" element={<Rates />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer className="footer" setUser={setUser} />
        </header>
      ) : (
        <header className="adminGrid">
          <div id="header">
            {" "}
            <AdminTopBar user={user} setUser={setUser} />
          </div>
          <div id="sidebar">
            <AdminNav selected={selected} setSelected={setSelected} />
          </div>
          <div id="main">
            <Routes>
              <Route
                path="/admin/"
                element={<AdminUser replace to="/admin" user={user} selected={selected} />}
              />
              <Route
                path="/admin/conditions"
                element={<AdminConditions replace to="/admin" />}
              />
              <Route
                path="/admin/prices"
                element={<AdminPrices replace to="/admin" />}
              />
              <Route
                path="/admin/faq"
                element={<AdminFaq replace to="/admin" user={user} />}
              />
              <Route
                path="/admin/products"
                element={<AdminProducts replace to="/admin" />}
              />
              <Route
                path="/admin/services"
                element={<AdminServices replace to="/admin" />}
              />
              <Route
                path="/admin/team-members"
                element={<AdminTeamMembers replace to="/admin" />}
              />
              <Route path="*" element={<AdminUser user={user} />} />
            </Routes>
          </div>
        </header>
      )}
      {!user ? (
        <div>
          <Scroll />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default App;
