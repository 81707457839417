import React from "react";
import "./our-process.css";

// ----- Image Imports -----
import bannerImg from "../../../images/ourProcess/PEWI-19.webp";
import cesarProfile from "../../../images/ourProcess/PEWI-3 10.webp";
import card1 from "../../../images/ourProcess/card-1-image.webp";
import card2 from "../../../images/ourProcess/card-2-image.webp";
import card3 from "../../../images/ourProcess/card-3-image.webp";
import card4 from "../../../images/ourProcess/card-4-image.webp";

export default function OurProcess() {

  function handleClick() {
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("readMoreBtn");

    if (btnText.innerHTML === "Read More...") {
      btnText.innerHTML = "Read Less..."; 
      moreText.style.display = "inline";
    } else {
      btnText.innerHTML = "Read More..."; 
      moreText.style.display = "none";
    }
  }

  return (
    <>
      <div className="ourProcess-page">
        <div className="ourProcess-banner">
            <img src={bannerImg} alt="banner-img" />
        </div>
        <div className="ourProcess-content-container">
          
          <div className="aboutUs-card">
            <h1 className="card-title">ABOUT US</h1>
            <div className="card-body">
              <div className="cesar-profile">
                <img src={cesarProfile} alt="cesar-profile" />
              </div>
              <div className="aboutUs-text-lg-screen">
                The Pro Evolution Wellness Inc. team is committed to redefining
                healthcare within our community through enhancement of
                rehabilitation and recovery. We identify obstacles to performance
                and create an individualized treatment plan catered to everyone’s
                needs and goals.
                <br />
                <br />
                Our clinic owner and functional trainer, Cesar Quintero has been
                working in the industry for 12 years. Rehabilitation and recovery
                has evolved drastically over the years and he has progressed with
                these changes.
                <br />
                <br />
                We are happy to offer members the most advanced technology,
                consisting of Normatec Recovery Boots, Hyperice Percussion massage
                tools, Hyperice Venom Back braces, Class IC Laser Therapy and much
                more.
                <br />
                <br />
                Our team is trained in many different manual therapies, ensuring
                that all clients receive exactly what they want and need to
                achieve optimal health and functionality.
              </div>
              <div className="aboutUs-text-sm-screen">
                Pro Evolution Wellness Inc. is an innovative wellness clinic that 
                believes our neighbours should live in good health! 
                <br />
                <br />
                We provide the community with chiropractic care, massage therapy, 
                physiotherapy, holistic nutrition counselling, functional 
                rehabilitation training, and MVA care. 
                
                <span id="more">
                <br />
                <br />
                Our team of professionals share the goal of in providing patients 
                with the best care possible in a positive and professional clinic 
                environment. 
                <br />
                <br />
                Located in Vaughan West, one of the fastest growing communities 
                outside of the Greater Toronto Area, our health care providers 
                and staff welcome all patients seeking rehabilitative care in a 
                friendly and inviting atmosphere.
                </span>
                <br />
                <br />
                <div className="read-btn-container">
                  <button onClick={() => handleClick()} id="readMoreBtn" className="readBtn">Read More...</button>
                </div>
              </div>
            </div>
          </div>

          <div className="ourProcess-Card">
            <h1 className="card-title">OUR PROCESS</h1>
            <div className="card-body">
              <div className="quadProcess">
                <div className="quad-card">
                  <div className="quad-card-content">
                    <div className="quad-img">
                      <img src={card1} alt="card1-icon" />
                    </div>
                    <div className="quad-title">An Informed Examination</div>
                    <div className="quad-desc">
                      This will take place on your first visit, so that we can
                      make or confirm a diagnosis, and understand the state and
                      stage of your injury. We’ll check your medical history, find
                      out about your level of physical activity and any relevant
                      lifestyle factors.
                    </div>
                  </div>
                </div>
                <div className="quad-card">
                  <div className="quad-card-content">
                    <div className="quad-img">
                      <img src={card2} alt="card2-icon" />
                    </div>
                    <div className="quad-title">A Professional Diagnosis</div>
                    <div className="quad-desc">
                      We’ll diagnose the source of your injury, and establish
                      whether it has caused any knock-on effects within your body.
                      Your physical therapist will also help you understand your
                      condition.
                    </div>
                  </div>
                </div>
                <div className="quad-card">
                  <div className="quad-card-content">
                    <div className="quad-img">
                      <img src={card3} alt="card3-icon" />
                    </div>
                    <div className="quad-title">A Unique Treament Plan</div>
                    <div className="quad-desc">
                      We’ve learned in our practice that no two patients are
                      exactly alike, which is why your unique history, condition
                      and goals will be reflected in the personalized treatment
                      plan we put together for you.
                    </div>
                  </div>
                </div>
                <div className="quad-card">
                  <div className="quad-card-content">
                    <div className="quad-img">
                      <img src={card4} alt="card4-icon" />
                    </div>
                    <div className="quad-title">Home Improvement Assignments</div>
                    <div className="quad-desc">
                      As part of your treatment plan, we are also likely to assign
                      you home exercises to help you heal faster, strengthen your
                      system and achieve your goals.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
