import React from "react";
import { Link } from "react-router-dom";
import "./AdminNav.css";

const AdminNav = ({ selectFunc, selected, setSelected }) => {

  console.log("AdminNav/selected")
  console.log(selected)

  function handleSelect(selectedItem) {
    if (selected !== selectedItem) {
      setSelected(selectedItem)
    }

  }

  return (
    <div id="admin-nav-container">
      <button
        onClick={() => handleSelect("admin")}
        type="button"
        name="admin"
        className={
          selected === "admin"
            ? "admin-nav-button-selected"
            : "admin-nav-button"
        }
        
      >
        <Link to="/admin/" className="admin-nav-link">Admin</Link>
      </button>
      <button
        onClick={() => handleSelect("team")}
        name="team"
        className={
          selected === "team" ? "admin-nav-button-selected" : "admin-nav-button"
        }
      >
        <Link to="/admin/team-members" className="admin-nav-link">Team Members</Link>
      </button>
      <button
        onClick={() => handleSelect("services")}
        name="services"
        className={
          selected === "services"
            ? "admin-nav-button-selected"
            : "admin-nav-button"
        }
      >
        <Link to="/admin/services" className="admin-nav-link">Services</Link>
      </button>
      <button
        onClick={() => handleSelect("conditions")}
        name="conditions"
        className={
          selected === "conditions"
            ? "admin-nav-button-selected"
            : "admin-nav-button"
        }
      >
        <Link to="/admin/conditions" className="admin-nav-link">Conditions</Link>
      </button>
      <button
        onClick={() => handleSelect("prices")}
        name="prices"
        className={
          selected === "prices"
            ? "admin-nav-button-selected"
            : "admin-nav-button"
        }
      >
        <Link to="/admin/prices" className="admin-nav-link">Prices</Link>
      </button>
      <button
        onClick={() => handleSelect("faq")}
        name="faq"
        className={
          selected === "faq" ? "admin-nav-button-selected" : "admin-nav-button"
        }
      >
        <Link to="/admin/faq" className="admin-nav-link">FAQ</Link>
      </button>
      <button
        onClick={() => handleSelect("products")}
        name="products"
        className={
          selected === "products"
            ? "admin-nav-button-selected"
            : "admin-nav-button"
        }
      >
        <Link to="/admin/products" className="admin-nav-link">Products</Link>
      </button>
    </div>
  );
};

export default AdminNav;
