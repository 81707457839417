// import React from "react";
// import { useState } from "react";
// import { v4 } from "uuid";
// import { postProduct } from "../../utils/product";
import storage from "../../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import 'firebase/storage';

export async function ImageFireBase(file) {
  const storageRef = ref(storage, `/files/${file.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function deleteImageFireBase(imgRef) {
  const desertRef = ref(storage, imgRef)
  deleteObject(desertRef).then(() => {
    // File deleted successfully
    console.log("File deleted successfully")
  }).catch((error) => {
    // An error occurred!
    console.log(`Error: ${error}` )
  })
}
//   const [file, setFile] = useState("");
//   const [percent, setPercent] = useState(0);

// Handles input change event and updates state
//   function handleChange(event) {
//     setFile(event.target.files[0]);
//   }

//   const handleOnSubmit =(e) => {
//     e.preventDefault();
//     if (!file) {
//       alert("Please upload an image first!");
//     }
//   uploadTask.on(
//     "state_changed",
//     //   (snapshot) => {
//     //     // const percent = Math.round(
//     //     //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//     //     // ); // update progress
//     //     // setPercent(percent);
//     //   },
//     (err) => console.log(err),
//     () => {
//       // download url
//       getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//         console.log(url, "<------------------");
//         postProduct({ name: "Leo", img: url, desc: "test" });
//       });
//     }
//   );

//   return (
//     <>
//       <h1>hello world, admin</h1>
//       <form onSubmit={handleOnSubmit}>
//         <input type="file" accept="image/*" onChange={handleChange}></input>
//         <button type="submit">Submit</button>
//       </form>
//     </>
//   );
// }
