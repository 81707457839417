const BASE_URL = "/api/rates";

// Add new rate to existing Services
export async function postServiceRate(id, data) {
  return await fetch(`${BASE_URL}/create/${id}`, serviceRatePost(data)).then(
    (res) => res.json()
  );
}

// Edit current rates for services
export async function editServiceRate(id, data) {
  return await fetch(`${BASE_URL}/update/${id}`, serviceRateUpdate(data)).then(
    (res) => res.json()
  );
}

// Delete rates for services
export async function deleteRate(serviceId, rateId) {
  return await fetch(
    `${BASE_URL}/delete/${serviceId}`,
    serviceRateDelete(rateId)
  ).then((res) => res.json());
}

// helper functions

function serviceRatePost({ name, price, is_hst }) {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ name, price, is_hst }),
  };
}

function serviceRateUpdate({ rateId, name, price, is_hst }) {
  return {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ rateId, name, price, is_hst }),
  };
}

function serviceRateDelete({ serviceId, rateId }) {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ serviceId, rateId }),
  };
}

