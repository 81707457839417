import React, { useState, useEffect } from "react";
import TeamCard from "../../../components/Cards/TeamCard/TeamCard";
import * as ourTeamApi from "../../../utils/ourTeamApi";
import "./our-team.css";

export default function OurTeam() {
  const [team, setTeam] = useState();
  // const [error, setError] = useState();


  useEffect(() => {

    async function getAll() {
      try {
        let allStaff = await ourTeamApi.getAllStaff();
        setTeam(allStaff);
        console.log(allStaff);
      } catch (err) {
        console.log(err)
        // setError(err);
        // console.log(error)
      }
    }

    getAll();
  }, []);

  console.log(team);

  return (
    <>
      <div className="team-container">
        <div className="team-content-container">
          <h1 className="our-team-title">MEET THE TEAM</h1>
          <div className="our-team-cards">
            {team &&
              team.map((t) => (
                <div className="team-card-container">
                  <TeamCard
                    title={`${t.firstName} ${t.lastName}`}
                    desc={t.jobTitle}
                    image={t.img}
                    link={t.linkToJane}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
