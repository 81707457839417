import React, { useState } from "react";
import { ImageFireBase, deleteImageFireBase } from "../../utils/Service/firebase";
import { postProduct } from "../../utils/product";
import { editProduct } from "../../utils/product";
import "./ProductModalForm.css";

const ModalForm = ({
  modalChange,
  modalOpen,
  productId,
  setProductId,
  products,
}) => {
  let productEdit = { name: "", desc: "" };
  let productImgEdit = null;

  if (productId) {
    let productFound = products.find((product) => product._id === productId);
    productEdit = {
      name: productFound.name,
      desc: productFound.desc,
    };
    productImgEdit = productFound.img;
  }

  const [img, setImg] = useState(productImgEdit);
  const [formData, setFormData] = useState(productEdit);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null)
  const { name, desc } = formData;

  const handleChange = (e) => {
    const newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(newFormData);
  };

  const handleDeleteFile = async (e) => {
    setImageToBeDeleted(img)
    document.getElementById('img').value = "";
    setImg("");
  }

  const handleFileUpload = async (e) => {
    setImg(e.target.files[0]);
    const img = await ImageFireBase(e.target.files[0]);
    setImg(img);
    setImageToBeDeleted(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageToBeDeleted) await deleteImageFireBase(imageToBeDeleted);
    
    const data = {
      name,
      desc,
      img,
    };
    if (productId === null) {
      await postProduct(data);
      alert("Product Create Successful!");
    } else {
      await editProduct(productId, data);
      alert("Product Updated Successful!");
    }
    setProductId(null);
    setFormData({
      name: "",
      desc: "",
    });
    setImg(null);
    modalChange(!modalOpen);
  };
  return (
    <div>
      <div className="admin__product">
        <div
          className="modal-admin-team-close-form"
          onClick={() => modalChange(!modalOpen)}
        >
          <div className="modal-x">X</div>
        </div>
        <div className="modal-admin-product-title-container">
          {productId === null ? (
            <span className="modal-admin-product-title">Add New Product</span>
          ) : (
            <span className="modal-admin-product-title">Edit Product</span>
          )}
        </div>
        <div>
          <form action="" onSubmit={handleSubmit}>
            <div className="product__section">
              <div className="img-with-choose-delete-file-container">
                <div className="img__section">
                  {img ? (
                    <img className="product__modal__img" src={img} alt="" />
                  ) : (
                    "Upload photo"
                  )}
                </div>
                <div>
                  <input
                    type="file"
                    name="img"
                    id="img"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e)}
                  />
                </div>
                <div className="modal-admin-product-delete-button-container">
                  <button type="button" onClick={(e) => handleDeleteFile(e)}>
                    Delete File
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <input
                    className="product__name__input"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <textarea
                    className="product__textarea__input"
                    type="text"
                    name="desc"
                    id="desc"
                    style={{ fontSize: 16, fontWeight: 700, height: "120px", textAlign: "left" }}
                    placeholder="Description"
                    value={desc}
                    onChange={handleChange}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <button type="submit" className="product__modal__btn">
                    {!productId ? "Add Product" : "Edit Product"}
                  </button>
                  <button
                    onClick={() => {
                      modalChange(!modalOpen);
                      setProductId(null);
                    }}
                    className="product__modal__btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
