import React from "react";
import "./home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

// ---- Image Imports ----
import car from "../../images/main/car-front.webp";
import chiro from "../../images/main/PEWI-13 3.webp";
import funRehab from "../../images/main/PEWI-25 3.webp";
import masTherapy from "../../images/main/PEWI-59 4.webp";
import perTrain from "../../images/main/PEWI-87 3.webp";
import recovery from "../../images/main/PEWI-152 3.webp";
import nutCon from "../../images/main/PEWI-171 2.webp";
import physio from "../../images/main/physio-pic.webp";
import acupunture from "../../images/main/Acupuncture-Fire-Cupping-tiny.webp";
import mva from "../../images/main/mva-services.webp";

import facilityPic1 from "../../images/main/PEWI-182 3.webp";
import facilityPic2 from "../../images/main/PEWI-205 3.webp";
import facilityPic3 from "../../images/Carousel/PEWI-79.webp";
import facilityPic4 from "../../images/Carousel/PEWI-152.webp";
import facilityPic5 from "../../images/Carousel/PEWI-193.webp";
import facilityPic6 from "../../images/Carousel/PEWI-204.webp";
import facilityPic7 from "../../images/Carousel/PEWI-223.webp";
import facilityPic8 from "../../images/Carousel/PEWI-224.webp";

// -- Main banner --
import mainBanner from "../../images/main/Desktop Hero 1.webp";

// -- Services --
import Button from "../../components/button/button";
import { Carousel } from "react-responsive-carousel";

// -- Testimonial Cards --
import TestimonialCard from "../../components/testimonial/testimonial";

function Home() {
  return (
    <div className="homePage-container">
      <div className="homePage-banner">
        <img src={mainBanner} alt="banner" className="banner-img" />
        <div className="banner-mva-button-container">
          <Button
              title="Book Appointment"
              href="https://proevolutionwellness.janeapp.com/"
              className="banner-mva-button"
          />
        </div>
      </div>

      {/* ---- statement sections - home page ----- */}
      <div className="homePage-statement">
        <h1>REHAB. PERFORMANCE. RECOVERY</h1>
        <div className="statement-bubble">
          <div className="quoteLeft-container">
            <ImQuotesLeft style={{ fontSize: "30px" }} />
          </div>
          <div className="statement">
            Our goal at Pro Evolution Wellness is management. We not only take
            pride in helping patients reduce pain, improve strength and restore
            mobility, but also to educate our patients on how to manage their
            condition so that they can achieve long-term health benefits. Our
            combined approaches of patient care and patient education ensures
            that we achieve our overall goal
          </div>
          <div className="quoteRight-container">
            <ImQuotesRight style={{ fontSize: "30px" }} />
          </div>
        </div>
      </div>
      {/* ---- our services section - home page ----- */}
      <div className="home-services-container">
        <h1>OUR SERVICES</h1>
        <div className="images-container">
          <div className="image-card">
            <img src={car} alt="front-car" className="image-card-img"/>
            <div className="img-title">MVA Services</div>
          </div>
          <div className="image-card">
            <img src={physio} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Physiotherapy</div>
          </div>
          <div className="image-card">
            <img src={chiro} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Chiropractic</div>
          </div>
          <div className="image-card">
            <img src={masTherapy} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Massage Therapy</div>
          </div>
          <div className="image-card">
            <img src={funRehab} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Functional Rehab</div>
          </div>
          <div className="image-card">
            <img src={perTrain} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Personal Training</div>
          </div>
          <div className="image-card">
            <img src={recovery} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Recovery</div>
          </div>
          <div className="image-card">
            <img src={acupunture} alt="acupunture-pic" className="image-card-img"/>
            <div className="img-title">Acupuncture</div>
          </div>
          <div className="image-card">
            <img src={nutCon} alt="physio-pic" className="image-card-img"/>
            <div className="img-title">Nutritional Consulting</div>
          </div>
        </div>
      </div>
      {/* ---- mva services section - home page ----- */}
      <div className="mva-container">
        <div className="mva-title">
          <h1>Motor Vehicle Accident (MVA) Services</h1>
        </div>
        <div className="mva-img-container">
          <img src={mva} alt="banner" className="banner-img" />
        </div>
        <div className="mva-subText">
          <p>
            Pro Evolution Wellness Clinic is an HCAI facility and a licensed
            provider for auto insurance rehabilitation in Ontario.
          </p>
          <p>
            We provide professional clinical treatment for people injured in
            vehicle-related accidents.
          </p>
        </div>
        <div className="mva-button-container">
          <Button
            title="Book Now"
            href="https://proevolutionwellness.janeapp.com/"
            className="mva-button"
          />
        </div>
      </div>
      {/* ---- our facilities section - home page ---- */}
      <div className="facilities-container">
        <div className="facilities-title">
          <h1>OUR FACILITIES</h1>
        </div>

        <Carousel
          showArrows={true}
          autoPlay={true}
          interval={3000}
          infiniteLoop={true}
          dynamicHeight={false}
          showIndicators={false}
          showThumbs={false}
          centerMode={true}
          centerSlidePercentage={100}
          
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className="carousel-arrow-prev-container"
                onClick={clickHandler}
              >
                <AiOutlineLeft className="carousel-arrow-prev"/>
              </div>
            );
          }}

          renderArrowNext={(clickHandler, hasPrev) => {
            return (
              <div
                className="carousel-arrow-next-container"
                onClick={clickHandler}
              >
                <AiOutlineRight className="carousel-arrow-next"/>
              </div>
            );
          }}
        
          className="facilities-carousel"
        >
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic1} className="carousel-img" alt="facility-1" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic2} className="carousel-img" alt="facility-2" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic3} className="carousel-img" alt="facility-3" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic4} className="carousel-img" alt="facility-4" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic5} className="carousel-img" alt="facility-5" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic6} className="carousel-img" alt="facility-6" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic7} className="carousel-img" alt="facility-7" />
            </div>
          </div>
          <div className="carousel-slide-container">
            <div className="carousel-img-container">
              <img src={facilityPic8} className="carousel-img" alt="facility-8" />
            </div>
          </div>
        </Carousel>
      </div>
      {/* ---- our location section - home page ---- */}
      <div className="locations-container">
        <div className="locations-title">
          <h1>OUR LOCATION</h1>
        </div>
        <div className="locations-img">
          <iframe
            title="proEvolution"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.594703490833!2d-79.56786758394337!3d43.84350097911519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2531610a3123%3A0xbb1dcdb52405cb2b!2sPro%20Evolution%20Wellness!5e0!3m2!1sen!2sca!4v1678209951368!5m2!1sen!2sca"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      {/* ---- testimonial section - home page ----- */}
      <div className="testimonial-container">
        <h1>TESTIMONIALS</h1>

        <Carousel
          showArrows={true}
          autoPlay={true}
          interval={3000}
          infiniteLoop={true}
          showThumbs={false}
          className="testimonial-carousel"
        >
          <TestimonialCard
            stars={5}
            desc={
              "Top quality wellness facility in Vaughan with extremely knowledgeable staff who provide high quality services. I have been seeing the strength rehab trainer, Cesar for almost 7 years following a motor vehicle accident. This rehabilitation has significantly helped me to get back on track and regain muscle strength following my injuries. The chiropractor and massage therapist at this clinic are also fantastic. Will continue to be a regular customer here."
            }
            name={"Natalie G"}
          />
          <TestimonialCard
            stars={5}
            desc={
              "I have been receiving amazing treatment here for about 5 years. I went from not being able to walk to working out daily. Encouraging, patient, empathetic, knowledgeable are just a few of the fantastic traits that all staff have. I have brought my family into this clinic and they also have received life changing treatment. I would truly reccomend coming to Pro Evolution and experiencing the fantastic treatment for your self. You will not regret it."
            }
            name={"Erica F"}
          />
          <TestimonialCard
            stars={5}
            desc={
              "I have tried other wellness clinics and their services do not compare to the team they have here. If you like wasting your time and money in a physio clinic where they do nothing but sit you down on a massage chair then this is not a place for you.I only trust Cesar and his team to maintain my back injury. They really care about your well-being and make sure you are well taken care of."
            }
            name={"John L"}
          />
        </Carousel>
      </div>
    </div>
  );
}

export default Home;
