import React, { useState } from "react";

const FaqComp = ({ quest, ans }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="faq-quest-comp">
        <div id="greenbox__front" onClick={() => setOpen(!open)}>
          <div id={open ? "plus-sign-vert-open" : "plus-sign-vert"}></div>
          <div id="plus-sign-hort"></div>
        </div>
        <div id="faq-quest-title">{quest ? quest : "loading...."}</div>
      </div>
      {open && <div id="ans">{ans}</div>}
      <div id="hard-line" />
    </>
  );
};

export default FaqComp;
