import React from "react";
import "./button.css";

export default function Button({ title, href }) {
  return (
    <>
      <button className="button">
        <a
          className="button-content"
          target="_blank"
          rel="noreferrer"
          href={href}
        >
          {title}
        </a>
      </button>
    </>
  );
}
